/*********************************************************************************
*   SECTION: NEW USER
*********************************************************************************/

#new_user {
    .new_user--height {
        min-height: calc(100vh - 225px);
        height: calc(100vh - 225px);

        img {
        }

        h4 {
            letter-spacing: 0.2px;
        }

        p {
            color: $gray-color;
            letter-spacing: 0.2px;
            line-height: 23px;
        }

        button {
            width: 50%;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}
