/***
*   SECTION: PENDING APPROVAL DETAIL
***/

#pending_approval_detail {
    &__header {
        h6 {
            font-weight: $weight__medium !important;
            margin: 0 1rem 0.2rem 0;
        }

        button {
            &.pending_approval__submit {
                padding: 0 !important;
            }
        }

        .dates_header {
            .mat-icon {
                color: $primary-color;
            }
        }
    }

    &__container {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;
    }
}
