#credit-balance.card,
#branches-credit-configuration {
    border-radius: 2px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);
}

#credit-balance.card {
    padding: 32px;

    img#total-credit {
        width: 160px;
        height: 160px;
    }

    .total-description {
        .title {
            color: rgba(0, 0, 0, 0);
            font-family: 'Mulish', sans-serif;
            font-size: 21px;
            letter-spacing: 0px;
        }
        .subtitle {
            color: rgba(0, 155, 117, 1);
            font-family: 'Mulish', sans-serif;
            font-weight: 700;
            letter-spacing: 0.23px;
        }
    }

    .spinner-container {
        strong {
            color: rgba(33, 33, 33, 1);
            letter-spacing: 0.23px;
        }

        .mat-progress-spinner svg circle {
            stroke: #009b75 !important;
            opacity: 1 !important;
        }

        .spinner-background {
            width: 160px !important;
            height: 160px !important;
            border-color: rgb(216, 216, 216) !important;
            line-height: 80px;
            border-radius: 50%;
            border-style: solid;
            border-width: 15px;
        }
    }
}

#branches-credit-configuration,
#top-metrics {
    .header {
        margin-bottom: 32px;

        .title {
            &__branches {
                font-size: 21px;
                opacity: 1;
                font-style: Regular;
                letter-spacing: 0px;
                text-align: left;
            }
            &__credit {
                font-size: 18px;
                color: rgba(0, 0, 0, 1);
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 0.15px;
                text-align: left;
                &--number {
                    color: #009b75;
                }
            }
        }

        .items {
            p {
                color: rgba(125, 124, 124, 1);
                // font-family: "Roboto-Regular";
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.44px;
                text-align: right;
            }
            a {
                color: rgba(0, 155, 117, 1);
                // font-family: "Roboto-Medium";
                font-size: 14px;
                font-weight: 500;
                font-style: Medium;
                letter-spacing: 1.25px;
                line-height: 16px;
                text-transform: uppercase;
                text-decoration: none;
            }
        }
    }

    .branch-label {
        border-radius: 10px;
        background-color: rgba(220, 243, 238, 1);
    }

    .save-button {
        transform: translateY(-9px);
    }

    .items-container {
        overflow-y: auto;

        .branch-icon {
            width: 17px;
            height: 20px;
        }

        mat-form-field .mat-form-field-wrapper {
            padding-bottom: 8px;
        }

        .mat-prefix {
            display: inline-block;
            transform: translateY(-4px);
        }

        &::-webkit-scrollbar {
            width: 8px; /* Set the width of the scrollbar */
        }

        &::-webkit-scrollbar-thumb {
            background-color: #dedede; /* Set the color of the scrollbar thumb */
            border-radius: 6px; /* Set the border radius to round the thumb */
        }

        &::-webkit-scrollbar-track {
            background-color: white; /* Set the color of the scrollbar track */
        }
    }
}

#top-metrics {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    .mat-tab-body-content {
        height: auto;
    }
}

.card-custom {
    max-width: 300px;

    .card-header-custom {
        background-color: #dcf3ee;
        border-bottom: 2px solid white;
    }
    .card-body-custom {
        background-color: #f7f7f7;

        .item {
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.53px;
            &:not(:last-of-type) {
                margin-bottom: 32px;
            }

            p {
                color: rgba(92, 91, 91, 1);
                margin: 0;
            }

            .item-title {
                font-weight: 500;
            }
        }
    }
}

@media (max-width: 768px) {
    .card .total-description .subtitle {
        font-size: medium !important;
    }
    .card .total-description .title {
        font-size: medium !important;
    }
}

@media (max-width: 320px) {
    .card .total-description .subtitle {
        font-size: medium !important;
    }
    .card .total-description .title {
        font-size: medium !important;
    }
}
