/***
*   SECTION: STANDALONE COMPONENT PAYMENT STATUS
***/

#payment_status {
    &__container {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;

        img {
            max-width: 320px;
        }

        &--rejected {
            background-color: $error-color-lighter;
            color: $color-black;
            border-radius: 2px;
            width: fit-content;
        }

        &--cancelled {
            background-color: $warning-color;
            color: $color-black;
            border-radius: 2px;
            width: fit-content;
        }

        &--started,
        &--approved {
            color: $color-black;
            border-radius: 2px;

            mat-icon {
                color: $primary-color;
            }
        }

        &--info {
            background-color: $text-color-dark;
            color: $color-black;
            border-radius: 2px;
        }

        &--location {
            color: $color-white;
            font-weight: 700;
            font-size: 18px;
            span {
                font-size: 1rem;
                font-weight: 400;
            }
        }
    }
    .notifications {
        background-color: $info-color !important;
    }
}
