/*********************************************************************************
*   SECTION: PAYMENT METHODS
*********************************************************************************/

#paymentMethods {
    &__header {
        h6 {
            font-weight: $weight__medium !important;
            margin: 0 1rem 0.2rem 0;
        }
    }

    &__container {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;

        @include media-breakpoint-down(md) {
            padding: 1.5rem !important;
        }

        .mat-tab-group {
            .mat-tab-header {
                margin: 1em 0;
            }

            .mat-tab-label {
                text-transform: uppercase;
                letter-spacing: 0.4px;
                font-weight: $weight__medium;
                font-size: 16px;
                background-color: unset;
                opacity: 1;
                color: $gray-color;

                &-active {
                    color: $primary-color;
                }
            }
        }

        .mat-progress-spinner circle,
        .mat-spinner circle {
            stroke: $info-color;
        }

        :not(spinner-container).spinner-container {
            position: relative;

            .spinner-background {
                position: absolute;
                width: 150px;
                height: 150px;
                line-height: 80px;
                overflow: hidden;
                border-color: rgba(103, 58, 183, 0.12);
                border-radius: 50%;
                border-style: solid;
                border-width: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
            }
        }

        .mat-subheader {
            .mat-subheader__container {
                @include media-breakpoint-down(md) {
                    width: 100% !important;
                    display: block !important;
                }
            }

            .mat-subheader__title {
                @include media-breakpoint-down(md) {
                    display: none !important;
                }
            }

            .mat-subheader__title,
            .mat-subheader__button {
                @include media-breakpoint-down(md) {
                    width: 100% !important;
                }
            }

            .mat-subheader__button {
                button {
                    margin-top: 16px !important;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
            }
        }

        img {
            height: 50px;
            width: auto;
            padding: 1px;

            &.noPaymentMethod {
                height: 200px;
                width: auto;
            }
        }

        .payment__ending {
            font-size: 14px;

            .payment__ending--expired {
                color: $error-color;
            }
        }

        .sprintPayCredit__title {
            color: $color-white;
            background-color: $info-color;
            height: 40px;

            @include media-breakpoint-up(md) {
                width: 80%;
                margin: 0 auto;
            }
        }

        .btn__sprintpay--linkDelete {
            padding: 0 !important;
        }
    }
}
