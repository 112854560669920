/*********************************************************************************
*   SECTION: CARD INVOICE
*********************************************************************************/

#card-invoice {
    h6 {
        font-weight: $weight__medium !important;
        margin: 0 1rem 0.2rem 0;
    }

    button {
        &.add_to_cart {
            padding: 0 !important;
        }

        &.pay-with-bank-debit {
            min-width: 200px !important;
        }
    }

    .invoice {
        flex-wrap: wrap;
        @include media-breakpoint-up(lg) {
            width: 15rem;
            flex: 1 0 auto !important;
        }
        @include media-breakpoint-up(md) {
            flex: 0 0 auto !important;
        }

        .card {
            background-color: $color-white;
            box-shadow: 0 1px 4px 0 $containers-color-opacity !important;

            button {
                font-weight: $weight__medium;
                font-size: 0.75rem;
                height: 1rem;
                line-height: initial;

                &.select_invoice {
                    padding: 0 !important;
                }
            }

            span {
                &.title {
                    font-size: 1rem;
                    height: 1.5rem;
                    letter-spacing: 0.4px;
                }

                &.subtitle {
                    color: $gray-color;
                    font-size: 0.75rem;
                    height: 1.4rem;
                    letter-spacing: 0.4px;
                }

                &.information {
                    font-size: 1rem;
                    height: 1.5rem;
                    letter-spacing: -0.6px;
                }
            }

            .overdue {
                border-top: 1px solid $grey-color-20;
                border-top-style: dashed;

                span {
                    size: 0.75rem;
                    height: 1rem;
                    letter-spacing: 0.4px;

                    label {
                        color: $error-color;
                    }
                }
            }
        }

        .selected {
            border: 2px solid $primary-color;
        }
    }
}
