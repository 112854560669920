/*********************************************************************************
*   SECTION: LOGIN
*********************************************************************************/

#login {
    .login__background {
        //background: bg-color bg-image position/bg-size bg-repeat bg-origin bg-clip bg-attachment initial|inherit;
        background: url('~src/assets/images/login/login-background.jpg') 0 0 / cover no-repeat;
        min-height: 100%;

        span {
            font-size: 45px;
            line-height: 56px;
            font-weight: $weight__bold;
            letter-spacing: 1px;
            color: $color-white;

            @include media-breakpoint-down(lg) {
                font-size: 4vw;
                line-height: 50px;
            }

            @include media-breakpoint-down(md) {
                font-size: 26px;
            }
        }
    }

    img {
        &.login__form--logo {
            height: 50px;
            object-fit: contain;
        }
    }

    button {
        &.help {
            box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.05);
            align-self: flex-end;
            margin: 0 1rem 1rem 0;

            @include media-breakpoint-down(sm) {
                width: 85%;
                margin: 0;
                padding: 0 !important;
                align-self: center;
                text-align: left;
                box-shadow: none;
                border: none !important;
                outline: none;
                text-transform: uppercase;
            }
        }
    }

    .main {
        @include media-breakpoint-down(sm) {
            width: 85% !important;
        }
    }

    form {
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }

        a {
            color: $primary-color;

            &.forgot__link {
                font-size: 0.8rem;

                &:hover {
                    color: $primary-color;
                    text-decoration: underline !important;
                }

                @include media-breakpoint-down(md) {
                    text-align: center !important;
                    justify-content: center;
                    margin-bottom: 2.5rem;
                    font-size: 1rem;
                }
            }
        }
    }

    .extra {
        &__account,
        &__fedexuser {
            color: $text-color-light;

            &--link {
                color: $primary-color;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }

        &__terms {
            color: $gray-color;

            &--link {
                color: $primary-color;
            }
        }
    }

    img {
        &.powered {
            bottom: 1rem;
            height: 35px;
            right: 1rem;
        }
    }

    .font-small {
        font-size: 0.7rem;
    }
}

.helpContactAlert {
    a {
        color: $primary-color;

        &:hover {
            text-decoration: underline !important;
        }
    }

    @include media-breakpoint-down(sm) {
        font-size: 0.9rem;
        text-align: left;
    }
}
.swal2-image {
    padding-top: 3rem !important;
}
