/*********************************************************************************
*   SECTION: PAYMENT HISTORY
*********************************************************************************/

#payment_history {
    &__header {
        h6 {
            font-weight: $weight__medium !important;
            margin: 0 1rem 0.2rem 0;
        }

        button {
            &.download {
                padding: 0 !important;
            }
        }
    }

    &__container {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;

        @include media-breakpoint-down(md) {
            padding: 1.5rem !important;
        }

        table.mat-table,
        mat-paginator.mat-paginator {
            background-color: transparent !important;
        }
    }
}
.lh-35 {
    line-height: 35px !important;
}
