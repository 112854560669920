/*********************************************************************************
*   SECTION: UPLOAD FILE BY PORT
*********************************************************************************/
#upload-file-by-port {
    .payment-form-container {
        padding: 0 24px 24px 24px;
    }

    .payment-instructions {
        border-radius: 9px;
        background-color: $grey-color-5;
        padding: 20px;

        &-download-csv {
            color: $primary-color;
            font-family: $family__body;
            font-size: 1em;
            background: transparent;
            cursor: pointer;
            margin-top: 14px;
        }
    }

    .payment-uploaded-csv {
        border: 1px solid $grey-color-20;
        border-radius: 9px;
        padding: 14px 0;
        .file-icon {
            img {
                height: 60px;
                width: 45px;
            }
        }
        .file-info-area {
            width: 60%;
            .file-info-area-body_left {
                flex: 1;

                .file-name {
                    color: $grey-color-100;
                    font-family: $family__body;
                    font-size: 1em;
                    line-height: 17px;
                    margin-left: 16px;
                    padding-bottom: 6px;
                }
                .file-size {
                    color: $grey-color-60;
                    font-family: $family__body;
                    font-size: 0.75em;
                    line-height: 13px;
                    margin-left: 16px;
                    margin-bottom: -6px;
                }
            }
            .progress {
                height: 16px;
                border: 1px solid $grey-color-5;
                margin: 10px 16px;
                width: 96%;
                .progress-bar {
                    background-color: $primary-color;
                }
            }
        }
    }

    .swal-payment-popup {
        table {
            tbody {
                tr {
                    td.payment-awb {
                        .payment-awb__failed {
                            color: $grey-color-10;
                        }

                        mat-icon {
                            font-size: 18px;
                            line-height: 24px;
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
    }
}
