@use 'sass:math';

@import 'sweetalert2/src/variables';

// POPUP
$swal2-width: 32em !default;
$swal2-padding: 0 0 1.25em !default;
$swal2-border: none !default;
$swal2-color: lighten($swal2-black, 33) !default;
$swal2-border-radius: 4px !default;
$swal2-box-shadow: #d9d9d9 !default;

// override SASS variables here
// CONFIRM BUTTON
$swal2-confirm-button-border: 0 !important;
$swal2-confirm-button-border-radius: 4px !important;
$swal2-confirm-button-background-color: $sa-main-color !important;
$swal2-confirm-button-color: $sa-text-color-dark !important;
$swal2-confirm-button-focus-box-shadow: none !important;

// DENY BUTTON
$swal2-deny-button-border: 0 !important;
$swal2-deny-button-border-radius: 4px !important;
$swal2-deny-button-background-color: $sa-warn-color !important;
$swal2-deny-button-color: $sa-text-color-dark !important;
$swal2-deny-button-focus-box-shadow: none !important;

// CANCEL BUTTON
$swal2-cancel-button-border: 0 !important;
$swal2-cancel-button-border-radius: 4px !important;
$swal2-cancel-button-background-color: $sa-warn-color !important;
$swal2-cancel-button-color: $sa-text-color-dark !important;
$swal2-cancel-button-focus-box-shadow: none !important;

// ACTIONS
$swal2-actions-justify-content: flex-end !important;
$swal2-actions-padding: 0 1em !important;

// CLOSE BUTTON
$swal2-close-button-border-radius: 50% !important;
$swal2-close-button-hover-background: rgb(242, 242, 242) !important;
$swal2-close-button-hover-color: $swal2-close-button-color !important;
$swal2-close-button-focus-box-shadow: none !important;

// CHECKBOX
$checkbox-size-px: 18 !important;
$checkbox-left-px: -2 !important;

// CUSTOM STYLE FOR PAYMENTS MODAL
.swal-payment-popup {
    tbody {
        .prefix-icon {
            font-size: 18px;
            line-height: 24px;
            margin-right: 12px;
        }

        .warning {
            color: $grey-color-10;
        }

        .line-height-th {
            line-height: 24px;
        }
    }
}

@import 'sweetalert2/src/sweetalert2';
