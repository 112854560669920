/*********************************************************************************
*   SECTION: CART
*********************************************************************************/

#cart {
    h6 {
        font-weight: $weight__medium !important;
        margin: 0 1rem 0.2rem 0;
    }
    .terms-of-use-link {
        color: #007e59;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.25px;
        text-decoration-line: underline;
        text-decoration-style: solid;
    }
}

.swal-container {
    margin: 1em 1.6em 0.3em !important;
    padding-bottom: 1rem !important;
}
