@use '@angular/material' as mat;
@include mat.core();

$custom-primary-palette: (
    50: #dcf3ee,
    100: #b2dfdb,
    200: #80cbc4,
    300: #60eecd,
    400: #4db6ac,
    500: #14bb9c,
    600: #009688,
    700: #00897b,
    800: #00796b,
    900: #00695c,
    contrast: (
        50: #000,
        100: #000,
        200: #000,
        300: #000,
        400: #fff,
        500: #fff,
        600: #fff,
        700: #fff,
        800: #fff,
        900: #fff,
    ),
);
$custom-typography: mat.define-typography-config(
    $font-family: '"Roboto", sans-serif;',
    $headline-1:
        mat.define-typography-level(
            $size__h1,
            calc(#{$size__h1} * #{$gr-lineHeight}),
            $weight__medium,
            $family__headers
        ),
    $headline-2:
        mat.define-typography-level(
            $size__h2,
            calc(#{$size__h2} * #{$gr-lineHeight}),
            $weight__medium,
            $family__headers
        ),
    $headline-3:
        mat.define-typography-level(
            $size__h3,
            calc(#{$size__h3} * #{$gr-lineHeight}),
            $weight__medium,
            $family__headers
        ),
    $headline-4:
        mat.define-typography-level(
            $size__h4,
            calc(#{$size__h4} * #{$gr-lineHeight}),
            $weight__medium,
            $family__headers
        ),
    $headline-5:
        mat.define-typography-level(
            $size__h5,
            calc(#{$size__h5} * #{$gr-lineHeight}),
            $weight__medium,
            $family__headers
        ),
    $headline-6:
        mat.define-typography-level(
            $size__h6,
            calc(#{$size__h6} * #{$gr-lineHeight}),
            $weight__medium,
            $family__headers
        ),
    $caption: mat.define-typography-level(12px, 20px, $weight__regular),
    $button: mat.define-typography-level(14px, 14px, $weight__medium),
    $overline: mat.define-typography-level(inherit, 1.125, $weight__regular),
);
$custom-primary: mat.define-palette($custom-primary-palette);
$custom-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$custom-warn: mat.define-palette(mat.$red-palette);

$theme__main: mat.define-light-theme(
    (
        color: (
            primary: $custom-primary,
            accent: $custom-accent,
            warn: $custom-warn,
        ),
        typography: $custom-typography,
    )
);
@include mat.all-component-themes($theme__main);

body.mat-typography {
    font:
        300 16px / calc(16px * 1.618) Roboto,
        sans-serif;
    h5 {
        font: $weight__bold $size__h5 / calc(#{$size__h5} * #{$gr-lineHeight}) $family__headers !important;
        letter-spacing: 0.6px !important;
    }

    h6 {
        font: $weight__bold $size__h6 / calc(#{$size__h6} * #{$gr-lineHeight}) $family__headers !important;
        letter-spacing: 0.6px !important;
    }

    .mat-display-1,
    .mat-display-2,
    .mat-display-3,
    .mat-display-4 {
        margin: 0 0 35px !important;
    }
}
.mat-option {
    font-size: $size__normal !important;
    font-weight: $weight__medium !important;
}

// GENERATE TEXT COLOR PRIMARY, ACCENT AND WANR

.mat-color {
    &__primary {
        color: $am-main-color !important;

        &--small {
            color: $am-main-color !important;
        }
    }

    &__accent {
        color: $am-secondary-color !important;
    }

    &__warn {
        color: $am-warn-color !important;
    }
}

// SET SPACE FOR MAT-ERROR

.mat-mdc-form-field-subscript-wrapper {
    display: none !important;
    height: auto !important;
    display: block !important;
    position: relative !important;
    padding-bottom: 6px;

    &:has(mat-error) {
        padding-bottom: 22px;
    }

    .mat-mdc-form-field-error-wrapper {
        padding-left: 0;
    }
}

// SET SPACER FOR STEPPER

.mat-vertical-stepper-header {
    padding: 24px 0 !important;
}

.mat-vertical-content-container {
    margin-left: 12px !important;
}

// CHECKBOX LABEL PREVENT FROM GETTING CUT

.mat-checkbox-layout {
    white-space: pre-wrap !important;
}

// SIDEBAR ITEM REMOVE

.swal2-cancel,
.swal2-confirm {
    min-width: 100px !important;
    width: auto !important;
}
.mat-list-text {
    padding-left: 0 !important;
}

.swal2-title {
    font-weight: 700 !important;
    font-size: $size__h5 !important;
    letter-spacing: 0.6px !important;
    padding: 0 !important;
    margin: 1rem 0 12px 0 !important;
    line-height: 48px !important;
    text-align: center !important;
    color: #032747 !important;
}

.swal2-html-container {
    padding: 1rem 1rem 0 1rem !important;
    text-align: center !important;
    margin: 0 !important;
}

// THEME CONFIGURATION (DON'T CHANGE ANYTHING UNTIL YOU REACH MAIN THEME CONFIGURATION')

// LIGHT THEME TEXT

$light-text: $am-text-color-light;
$light-primary-text: $light-text;
$light-secondary-text: rgba($light-primary-text, 0.54);
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$light-theme-foreground-palette: (
    base: $light-primary-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-primary-text, 0.26),
    disabled-text: $light-disabled-text,
    elevation: $light-primary-text,
    hint-text: $light-disabled-text,
    secondary-text: $light-secondary-text,
    icon: rgba($light-primary-text, 0.54),
    icons: rgba($light-primary-text, 0.54),
    text: rgba($light-primary-text, 0.87),
    slider-min: rgba($light-primary-text, 0.87),
    slider-off: rgba($light-primary-text, 0.26),
    slider-off-active: rgba($light-primary-text, 0.38),
);

// DARK THEME TEXT

$dark-text: $am-text-color-dark;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-secondary-text: rgba($dark-primary-text, 0.54);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$dark-theme-foreground-palette: (
    base: $dark-primary-text,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-primary-text, 0.3),
    disabled-text: $dark-disabled-text,
    elevation: $dark-primary-text,
    hint-text: $dark-disabled-text,
    secondary-text: $dark-secondary-text,
    icon: $dark-primary-text,
    icons: $dark-primary-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-primary-text, 0.3),
    slider-off-active: rgba($dark-primary-text, 0.3),
);

// LIGHT THEME BACKGROUND

$light-background: $am-background-color-light;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$light-bg-tooltip: lighten($am-text-color-dark, 20%);
$light-bg-alpha-4: rgba($am-text-color-dark, 0.04);
$light-bg-alpha-12: rgba($am-text-color-dark, 0.12);

$light-theme-background-palette: (
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    background: $light-background,
    hover: $light-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    disabled-button: $light-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10,
    tooltip: $light-bg-tooltip,
);

// DARK THEME BACKGROUND

$dark-background: $am-background-color-dark;
$dark-bg-darker-5: darken($dark-background, 5%);
$dark-bg-darker-10: darken($dark-background, 10%);
$dark-bg-darker-20: darken($dark-background, 20%);
$dark-bg-darker-30: darken($dark-background, 30%);
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-tooltip: lighten($am-text-color-dark, 20%);
$dark-bg-alpha-4: rgba($am-text-color-dark, 0.04);
$dark-bg-alpha-12: rgba($am-text-color-dark, 0.12);

$dark-theme-background-palette: (
    status-bar: $dark-bg-darker-20,
    app-bar: $dark-bg-darker-5,
    background: $dark-background,
    hover: $dark-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $dark-bg-darker-20,
    selected-disabled-button: $dark-bg-darker-30,
    disabled-button-toggle: $dark-bg-darker-10,
    unselected-chip: $dark-bg-darker-10,
    disabled-list-option: $dark-bg-darker-10,
    tooltip: $dark-bg-tooltip,
);

// LIGHT THEME CONFIGURATION

body {
    --primary-color: $am-main-color;
    --primary-lighter-color: $am-main-color-lighter;
    --primary-darker-color: $am-main-color-darker;
    --text-primary-color: #{$light-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary__light: (
    main: $am-main-color,
    lighter: $am-main-color-lighter,
    darker: $am-main-color-darker,
    200: $am-main-color,
    contrast: (
        main: $dark-primary-text,
        lighter: $dark-primary-text,
        darker: $dark-primary-text,
    ),
);

body {
    --accent-color: $am-secondary-color;
    --accent-lighter-color: $am-secondary-color-lighter;
    --accent-darker-color: $am-secondary-color-darker;
    --text-accent-color: #{$light-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent__light: (
    main: $am-secondary-color,
    lighter: $am-secondary-color-lighter,
    darker: $am-secondary-color-darker,
    200: $am-secondary-color,
    contrast: (
        main: $light-primary-text,
        lighter: $light-primary-text,
        darker: $light-primary-text,
    ),
);

body {
    --warn-color: $am-warn-color;
    --warn-lighter-color: $am-warn-color-lighter;
    --warn-darker-color: $am-warn-color-darker;
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn__light: (
    main: $am-warn-color,
    lighter: $am-warn-color-lighter,
    darker: $am-warn-color-darker,
    200: $am-warn-color,
    contrast: (
        main: $light-primary-text,
        lighter: $light-primary-text,
        darker: $light-primary-text,
    ),
);

$theme__light--primary: mat.define-palette($mat-primary__light, main, lighter, darker);
$theme__light--accent: mat.define-palette($mat-accent__light, main, lighter, darker);
$theme__light--warn: mat.define-palette($mat-warn__light, main, lighter, darker);

// DARK THEME CONFIGURATION

body {
    --primary-color: $am-main-color;
    --primary-lighter-color: $am-main-color-lighter;
    --primary-darker-color: $am-main-color-darker;
    --text-primary-color: #{$light-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary__dark: (
    main: $am-main-color,
    lighter: $am-main-color-lighter,
    darker: $am-main-color-darker,
    200: $am-main-color,
    contrast: (
        main: $dark-primary-text,
        lighter: $dark-primary-text,
        darker: $dark-primary-text,
    ),
);

body {
    --accent-color: $am-secondary-color;
    --accent-lighter-color: $am-secondary-color-lighter;
    --accent-darker-color: $am-secondary-color-darker;
    --text-accent-color: #{$light-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent__dark: (
    main: $am-secondary-color,
    lighter: $am-secondary-color-lighter,
    darker: $am-secondary-color-darker,
    200: $am-secondary-color,
    contrast: (
        main: $light-primary-text,
        lighter: $light-primary-text,
        darker: $light-primary-text,
    ),
);

body {
    --warn-color: $am-warn-color;
    --warn-lighter-color: $am-warn-color-lighter;
    --warn-darker-color: $am-warn-color-darker;
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn__dark: (
    main: $am-warn-color,
    lighter: $am-warn-color-lighter,
    darker: $am-warn-color-darker,
    200: $am-warn-color,
    contrast: (
        main: $light-primary-text,
        lighter: $light-primary-text,
        darker: $light-primary-text,
    ),
);

$theme__dark--primary: mat.define-palette($mat-primary__dark, main, lighter, darker);
$theme__dark--accent: mat.define-palette($mat-accent__dark, main, lighter, darker);
$theme__dark--warn: mat.define-palette($mat-warn__dark, main, lighter, darker);

.mat-flat-button-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-table,
.mat-paginator {
    background-color: $am-background-color-light !important;
    color: $am-text-color-light !important;

    .mat-flat-button-disabled {
        background-color: transparent !important;
    }
}

// MAT DATE RANGE PICKER
.mat-form-field-type-mat-date-range-input {
    .mat-form-field-infix {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    &.regular .mat-form-field-infix {
        padding-top: 1em;
        padding-bottom: 1em;
    }
}

//MAT BUTTON
.mat-stroked-button {
    min-height: 59px !important;
    padding: 0 15px !important;
}
.mat-form-field-suffix {
    display: flex !important;
    align-self: center !important;
}

//mat-hint
.phone-input {
    lib-phone-input {
        mat-hint {
            white-space: nowrap !important;
        }
    }
}
