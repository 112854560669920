/*********************************************************************************
*   SECTION: ACCOUNT
*********************************************************************************/
@use 'sass:math';

@import 'sweetalert2/src/variables';
#account {
    .backButton {
        position: absolute;
        top: 15px;
        left: 15px;
    }
    .account__container {
        margin: 0 auto;
        @include media-breakpoint-down(lg) {
            background-image: none;
        }

        .subTitle {
            color: #7d7c7c;
            font-size: 10px;
            font-weight: 400;
            letter-spacing: 1.5;
        }

        h5 {
            font-weight: 400 !important;
        }

        .mat-toolbar-single-row {
            padding: 0 20px;

            @include media-breakpoint-down(lg) {
                padding: 0;
            }
        }

        button {
            &.btn__stepper--first {
                @include media-breakpoint-down(lg) {
                    margin-bottom: 1em;
                }
            }
        }

        .existing__account {
            color: $text-color-light;

            &--link {
                color: $primary-color;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }

        .mat-step-label-active {
            .mat-step-text-label {
                color: $primary-color;
            }
        }

        .mr-40 {
            margin-right: 40px;
        }
    }
}
.swal2-html-container {
    .accountCreated {
        .title {
            font-weight: 400;
            font-size: 43px;
            color: #000;
            margin-bottom: 1.5rem;
        }
        .info {
            font-weight: 400;
            font-size: 1rem;
            color: #000;
            margin-bottom: 1.5rem;
            letter-spacing: 0.5px;
        }
    }
}
.mat-progress-spinner circle,
.mat-spinner circle {
    opacity: 0.2 !important;
}
.swal2-actions {
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
    width: 100% !important;
    justify-content: center !important;

    &.margin-top {
        margin-top: 1rem !important;
    }
}
.confirmButton {
    letter-spacing: 1.25px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    padding: 10px 40px !important;
    width: 50% !important;
}
$swal2-confirm-width: 50% !important;
@import 'sweetalert2/src/sweetalert2';
