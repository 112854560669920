/*********************************************************************************
*   SECTION: VOIDED PAYMENTS DETAIL
*********************************************************************************/
#payment_history_detail {
    &__header {
        h6 {
            font-weight: $weight__medium !important;
            margin: 0 1rem 0.2rem 0;
        }
        span {
            font-weight: $weight__regular !important;
            font-size: 21px;
        }
        .update_status {
            height: 38px !important;
            border: 1px solid #14bb9c !important;
            padding: 0 8px !important;
            font-size: 14px !important;
            letter-spacing: 1.25px;
            line-height: 16px !important;
            font-weight: $weight__medium !important;
        }
        .request-canceled {
            color: #ab161d;
            font-size: 14px;
        }
        button {
            &.payment_history__download {
                padding: 0 !important;
            }
        }

        .dates_header {
            .mat-icon {
                color: $primary-color;
            }
        }
    }

    &__container {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;

        .payment_history_detail__sections {
            background: $background-color-light !important;

            a {
                color: $primary-color;
                font-weight: $weight__regular;
                letter-spacing: 1.25px;
            }
        }

        p {
            color: $text-color-light;

            &.card-subtitle {
                color: $gray-color;
            }
        }
        &.status {
            white-space: nowrap;
        }
        .icon-info {
            mat-icon {
                font-size: 18px;
                color: #0d47a1;
            }
        }
    }
    mat-expansion-panel-header {
        background-color: #dcf3ee !important;
    }
    .details-by-section {
        background-color: #fff !important;
        border-radius: 4px;
    }
}
.menu-profile {
    .cancel-profile {
        color: #ab161d;
        font-weight: 600;
        letter-spacing: 0.4;
        i {
            font-weight: 600;
        }
    }
    .approve-profile {
        color: #005130;
        font-weight: 600;
        letter-spacing: 0.4;
        i {
            font-weight: 600;
        }
    }
}
mat-tooltip-component {
    .mat-tooltip {
        font-size: 12px;
        background-color: rgba(0, 0, 0, 0.7);
    }
}
.cancelReason {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0.25px;
    color: #000;
}
.approvedModal {
    label {
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 0.25px;
        color: #000;
    }
    .refund {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 25px;
        color: #000;
        .approved {
            color: #009b75;
        }
        .requested {
            color: #2196f3;
        }
    }
}

.actionsContainer {
    margin: 2rem 0 !important;
    padding: 0 2.1rem !important;
    width: 100% !important;
    justify-content: end !important;
}
.cancel {
    background-color: #fff !important;
    font-size: 14px !important;
    color: #14bb9c !important;
    border: 2px solid #14bb9c !important;
    height: 45px !important;
    margin-right: 1rem !important;
}
.confirm {
    font-size: 14px !important;
    margin: 0 !important;
}
