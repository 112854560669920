/*********************************************************************************
*   SECTION: PAYMENT METHODS
*********************************************************************************/

mat-dialog-container {
    &#paymentMethodsCart {
        width: 50%;
        margin: 0 auto;
        display: block;

        @include media-breakpoint-down(lg) {
            width: 70%;
            min-width: 70%;
        }

        @include media-breakpoint-down(md) {
            width: 80%;
            min-width: 80%;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            min-width: 100%;
        }
    }
}

#paymentMethods-cart {
    &__header {
        h6 {
            font-weight: $weight__medium !important;
            margin: 0 1rem 0.2rem 0;
        }
    }

    &__container {
        ul {
            &.nav {
                border: none;

                li {
                    flex: 1 1 auto;

                    &.nav-item {
                        margin: 1em 0;
                        cursor: pointer;

                        span {
                            padding: 1em 0;
                            text-align: center;
                            color: $gray-color;
                            border-width: 0 0 2px 0;
                            letter-spacing: 0.4px;
                            font-weight: $weight__medium;

                            &.active {
                                color: $primary-color;
                                border-bottom-color: $primary-color !important;
                            }
                        }
                    }
                }
            }
        }

        .mat-progress-spinner circle,
        .mat-spinner circle {
            stroke: $primary-color;
        }

        :not(spinner-container).spinner-container {
            position: relative;

            .spinner-background {
                position: absolute;
                width: 150px;
                height: 150px;
                line-height: 80px;
                overflow: hidden;
                border-color: rgba(103, 58, 183, 0.12);
                border-radius: 50%;
                border-style: solid;
                border-width: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
            }
        }

        .mat-subheader {
            .mat-subheader__container {
                @include media-breakpoint-down(md) {
                    width: 100% !important;
                    display: block !important;
                }
            }

            .mat-subheader__title {
                @include media-breakpoint-down(md) {
                    display: none !important;
                }
            }

            .mat-subheader__title,
            .mat-subheader__button {
                @include media-breakpoint-down(md) {
                    width: 100% !important;
                }
            }

            .mat-subheader__button {
                button {
                    margin-top: 16px !important;

                    @include media-breakpoint-down(md) {
                        width: 100%;
                    }
                }
            }
        }

        mat-list.scrollable {
            max-height: 50vh;
            overflow-x: auto;
        }

        mat-list-item {
            height: auto;

            @include media-breakpoint-down(sm) {
                .listItemWithScrollPadding {
                    padding-right: 12px;
                }
            }

            .sprintPayCredit__total {
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 33px;
                color: $color-black;
            }
        }

        img {
            height: 50px;
            width: auto;

            &.noPaymentMethod {
                height: 200px;
                width: auto;
            }
        }

        .payment__ending {
            font-size: 14px;

            span {
                font-weight: 300;
            }
        }

        .selectPaymentButton {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1.25px;
            line-height: 1rem;
        }
    }
}
