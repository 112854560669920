mat-dialog-container:has(#confirmation-dialog) {
    padding: 0;
}
#confirmation-dialog {
    padding: 32px;

    .icon {
        text-align: center;

        mat-icon {
            margin-bottom: 32px;
            height: 82px;
            width: 82px;
            font-size: 82px;
            color: #facea8;
        }
    }

    .description {
        margin-bottom: 32px;

        h1,
        h2 {
            color: rgba(92, 91, 91, 1);
            font-size: 34px;
            font-weight: 400;
            letter-spacing: 0.28px;
            text-align: center;
        }

        h2 {
            font-size: 18px;
        }
    }

    .buttons {
        button {
            min-width: 160px;

            &.btn-confirm {
                background-color: #009b75;
                color: white;
            }

            &.btn-cancel {
                color: #009b75;
                border: 2px solid #009b75;
            }
        }
    }
}
