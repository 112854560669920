/*********************************************************************************
*   SECTION: ACCOUNT TYPE
*********************************************************************************/

#account-type {
    figure {
        &.account-type__logo {
            height: 50px;
            margin-bottom: 32px;

            @include media-breakpoint-down(lg) {
                margin: 40px 0;
                height: 35px;
            }
        }
    }

    .account-type__company,
    .account-type__individual {
        border-radius: 6px;
        border: 2px solid transparent;
        min-height: 500px;
        margin-bottom: 1rem;
        transition: 0.1s all ease;

        figure {
            height: 250px;

            img {
                height: 100%;
            }
        }

        &:hover {
            a {
                display: flex !important;
                align-items: center;
                justify-content: center;
                color: $color-white;
            }
        }

        @include media-breakpoint-down(lg) {
            a {
                display: flex !important;
                align-items: center;
                justify-content: center;
                color: $color-white;
            }
        }
    }

    .mat-toolbar-single-row {
        padding: 0 20px;

        @include media-breakpoint-down(lg) {
            padding: 0;
        }
    }

    .existing__account {
        color: $text-color-light;

        &--link {
            color: $primary-color;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }
}
