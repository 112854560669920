.mdc-snackbar__label:has(#global-message),
.mdc-snackbar__surface:has(#global-message) {
    padding: 0;
}

#global-message {
    background-color: #dcf3ee;
    border-left: 4px solid #6ecfb9;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    .icon {
        margin-left: 14px;
        color: #007E59;
    }

    .message-content {
        margin-left: 16px;
        align-self: center;

        p.title {
            color: black;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 0;

            & ~ p {
                margin-bottom: 1.5rem;
            }
        }

        p {
            color: rgba(33, 33, 33, 1);
            font-size: 14px;
            font-weight: 400;
            opacity: 1;
            letter-spacing: 0.12px;
            line-height: normal;
        }
    }

    .close-icon {
        color: black;
    }
}
