/*********************************************************************************
*   SECTION: GRID
*********************************************************************************/

* {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
}

html,
body {
    width: 100%;
    clear: both;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
[contenteditable='true']:focus,
span,
mat-icon,
mat-list-item {
    outline: none;
    box-shadow: none;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    padding: 0 !important;
}

mat-error + mat-error {
    display: none !important;
}
