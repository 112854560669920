.payment-history-container {
    border-radius: 2px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.186353);
    background-color: rgba(255, 255, 255, 1);
    padding: 40px 33px 27px;

    .mat-mdc-table,
    .mat-mdc-paginator {
        background-color: white !important;

        thead {
            background-color: $grey-color-20;
        }
    }
}

#payment-detail-forwarders,
#invoice-detail-forwarders {
    .detail-header {
        background-color: $grey-color-5;
    }

    .details-by-section {
        background-color: #fff !important;
        border-radius: 4px;
    }
    .detail {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 32px;

        &__item {
            width: 100%;

            &--title {
                font-size: 14px;
            }
            &--value {
                font-size: 14px;
                font-weight: 500;
            }
        }
    }

    mat-expansion-panel-header {
        background-color: #dcf3ee !important;
    }
}
