@charset 'utf-8';

/*********************************************************************************
*   SECTION: IMPORT NORMALIZE LIBRARY
*********************************************************************************/

@import '../../node_modules/normalize.css/normalize.css';

/*********************************************************************************
*   SECTION: IMPORT FONTAWESOME LIBRARY STYLES
*********************************************************************************/

@import '../../node_modules/font-awesome/css/font-awesome.css';

/*********************************************************************************
*   SECTION: IMPORT BOOTSTRAP AND BOOTSTRAP ICONS LIBRARY STYLES
*********************************************************************************/

@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../node_modules/bootstrap-icons/font/bootstrap-icons.css';

/*********************************************************************************
*   SECTION: IMPORT ANGULAR MATERIAL PREBUILT THEME IN THIS CASE THE PURPLE GREEN
*********************************************************************************/

@import '../../node_modules/@angular/material/prebuilt-themes/purple-green.css';

/*********************************************************************************
*   SECTION: IMPORT MGX SPINNER
*********************************************************************************/

@import '../../node_modules/ngx-spinner/animations/square-jelly-box.css';

/*********************************************************************************
*   SECTION: MAIN
*********************************************************************************/

@import 'abstracts/mixins';
@import 'abstracts/container';

@import 'base/typography';
@import 'base/common-styles';

@import 'layout/grid';

@import 'themes/bootstrap-theme';
@import 'themes/material-theme';
@import 'themes/sweet-alert-theme';

@import 'components/generic/button';
@import 'components/generic/tooltip';
@import 'components/generic/snackbar';
@import 'components/generic/mat-list';
@import 'components/generic/cdk-virtual';
@import 'components/generic/ng-select';
@import 'components/generic/mat-form-field';
@import 'components/generic/slide-toggle';
@import 'components/ui/xng-breadcrumb';
@import 'components/ui/am-table';
@import 'components/ui/dynamic-table';
@import 'components/ui/no-information';
@import 'components/ui/timeline';
@import 'components/ui/payment-detail';
@import 'components/ui/download';
@import 'components/ui/refund-request';
@import 'components/ui/chips';
@import 'components/standalone/payment-status';
@import 'components/standalone/sidenav';
@import 'components/standalone/bulk-payment-result';
@import 'components/standalone/switch-profile';
@import 'components/standalone/refund-request-choice';
@import 'components/standalone/refund-request-modal';
@import 'components/standalone/verification-method';
@import 'components/standalone/form-header';
@import 'components/standalone/refund-status-update';
@import 'components/standalone/table-filter-by-column';
@import 'components/standalone/confirmation-dialog';
@import 'components/standalone/branch-credit-details';
@import 'components/standalone/global-message';
@import 'components/standalone/echecks';
@import 'components/standalone/refund-amount-update';
@import 'components/standalone/email-report-request-modal';

@import 'pages/login';
@import 'pages/admin';
@import 'pages/password/forgot-password';
@import 'pages/password/new-password';
@import 'pages/signup/account-type';
@import 'pages/signup/account-type-company';
@import 'pages/signup/account-type-individual';
@import 'pages/home/card-invoice';
@import 'pages/home/frequent_facilities';
@import 'pages/home/card-wallet';
@import 'pages/home/pending-approval';
@import 'pages/home/rejected-request';
@import 'pages/home/new-user';
@import 'pages/home/home';
@import 'pages/facilityPayments/pending-approval';
@import 'pages/facilityPayments/pending-approval-details';
@import 'pages/facilityPayments/payment_history';
@import 'pages/facilityPayments/voided-payments';
@import 'pages/facilityPayments/voided-payments-detail';
@import 'pages/facilityPayments/payment-history-detail';
@import 'pages/facilityPayments/rejected-payment';
@import 'pages/facilityPayments/rejected-request-details';
@import 'pages/facilityPayments/upload-request';
@import 'pages/facilityPayments/payment-methods';
@import 'pages/facilityPayments/new-payment';
@import 'pages/facilityPayments/upload-file-by-port';
@import 'pages/facilityPayments/dynamic-fields-payment';
@import 'pages/settings/settings';
@import 'pages/facilityContacts/facility-contacts';
@import 'pages/users/users';
@import 'pages/users/new-user';
@import 'pages/invoices/open';
@import 'pages/invoices/paid';
@import 'pages/cart/cart';
@import 'pages/cart/empty-cart';
@import 'pages/cart/invoices-list';
@import 'pages/cart/payment-detail';
@import 'pages/cart/payment-methods';
@import 'pages/cart/payment-confirmation';
@import 'pages/cart/payment-summary';
@import 'pages/cart/guest-signup-form';
@import 'pages/superAdminACH/ach-payment-detail';

@import 'pages/payment/payment-history';
@import 'pages/forwarder-admin/credit-balance';
@import 'pages/forwarder-admin/bank-debits';
@import 'pages/forwarder-admin/debit-history';

@import 'modules/payment-methods/new-payment-method/new-payment-method';

/*********************************************************************************
*   SECTION: https://github.com/ng-select/ng-select component
*********************************************************************************/

@import '~@ng-select/ng-select/themes/material.theme.css';

@import 'flag-icons/css/flag-icons.min.css';
