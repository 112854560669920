section.flight-information {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    padding-bottom: 20px;

    .flight-item {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.005em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        background-color: $primary-color-50;
        padding: 10px;
        height: auto;
        .flight-title-container {
            min-height: 40px;
            margin: 3px 0;
            padding: 5px 10px;

            span {
                color: black;
            }
        }
        .flight-item-values-container {
            display: flex;
            align-items: center;
            flex-direction: row;
            min-height: 40px;
            margin: 3px 0;
            padding: 5px 10px;
            border-radius: 4px;
            background-color: $primary-color-900;
            p,
            span {
                color: white;
            }
        }
    }
}

.flight-information-section {
    background-color: $primary-color-50;
    section.flight-information {
        min-width: 300px;
        min-height: calc(100vh);
    }
}

.modal-events {
    .data-container {
        min-height: 52px;
        background-color: $primary-color-900;
    }

    .title {
        font-size: 32px;
        opacity: 1;
        letter-spacing: 0px;
        text-align: left;
    }
}
