#verification_method {
    color: #000;
    .container {
        background-color: #f7f7f7;
        flex-wrap: wrap;
        word-break: break-word;
    }
    .text {
        color: #5c5b5b;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.12;
    }
    .info,
    .title {
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0.15;
    }
    .type {
        font-size: 16px;
        font-weight: 400;
    }
    .message {
        color: #7d7c7c;
        letter-spacing: 0.1;
        font-size: 12px;
        font-weight: 400;
    }
}
