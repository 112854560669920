/*********************************************************************************
*   SECTION: BUTTON
*********************************************************************************/

.mdc-button:not(:disabled).btn__sprintpay,
.mdc-button:disabled.btn__sprintpay,
button.btn__sprintpay,
p.btn__sprintpay {
    padding: 0 2rem !important;
    min-height: 50px;
    border-radius: 4px !important;
    transition: 0.3s;
    font-weight: $weight__bold;
    border-style: solid !important;
    border-width: 1px !important;
    white-space: unset !important;

    &--white {
        background-color: $color-white;
        border-color: $border-button !important;
        color: $primary-color;
    }

    &--green {
        background-color: $primary-color;
        border: none !important;
        color: $color-white;
    }

    &--greenDisabled {
        background-color: rgba(20, 187, 156, 0.5);
        border: none !important;
        color: $color-white;
    }

    &--greenBorder {
        background-color: $color-white;
        border: 2px solid $primary-color !important;
        color: $primary-color;
    }

    &--link {
        background-color: transparent;
        color: $primary-color;
        border: none !important;

        .mat-flat-button-focus-overlay {
            background-color: transparent;
        }
        &:disabled.mat-mdc-button-disabled {
            background-color: transparent !important;
            color: $gray-color !important;
        }
    }

    &--gray {
        background-color: $gray-color;
        border: none !important;
        color: $color-white;
    }

    &--linkBlack {
        background-color: transparent;
        color: $color-black;
        border: none !important;
        padding: 0 !important;

        .mat-flat-button-focus-overlay {
            background-color: transparent;
        }
    }

    &--delete {
        background-color: $warn-color;
        border: none !important;
        color: $color-white;
    }

    &--linkDelete {
        background-color: transparent;
        color: $warn-color;
        border: none !important;

        .mat-flat-button-focus-overlay {
            background-color: transparent;
        }
    }

    &--cancelProcess {
        padding: 0 24px !important;

        &:hover {
            background-color: transparent !important;
        }

        @include media-breakpoint-down(md) {
            padding: 0 !important;
        }
    }

    &--outlineDefault {
        color: #007E59;
        border: 2px solid #007E59 !important;
        background-color: transparent;
    }

    &--textDefault {
        color: $primary-color;
        border: 0;
        background-color: transparent;
    }

    &--multiLine {
        height: unset;
        min-height: 50px;
        white-space: normal !important;
        line-height: $gr-lineHeight !important;
    }

    &:disabled {
        background-color: rgba($primary-color, 0.5) !important;
        color: $color-white !important;
    }

    @include media-breakpoint-down(md) {
        margin-top: 1rem;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

button {
    &.year-btn {
        display: flex;
        align-items: center;
    }

    &.mdc-icon-button.mat-mdc-icon-button {
        padding: 0;

        span.calendar-header-label {
            font-size: 14px;
            line-height: 1;
        }
    }
}
.button-refresh {
    color: #007E59 !important;
    border: 2px solid #007E59 !important;
    background-color: transparent !important;
}
