#new-user {
    border-radius: 2px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.186353);
    opacity: 1;
    background-color: rgba(255, 255, 255, 1);

    .profile-info {
        margin-bottom: 72px;
    }

    mat-slide-toggle {
        color: rgba(33, 33, 33, 1);
        font-size: 14px;
        font-weight: 400;
    }

    input {
        -moz-appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .section-title {
        color: rgba(125, 124, 124, 1);
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
        line-height: 8.5px;
    }

    .actions {
        button {
            width: 174px;
        }
    }

    .center-invoice-prefix {
        transform: translateY(-4px);
    }

    .center-prefix {
        transform: translateY(-7px);
    }

    .phone-input + span {
        margin-left: 5px;
    }
}

#userForm {
    .mat-form-field-disabled .center-invoice-prefix {
        color: rgba(3, 39, 71, 0.5);
    }
}
