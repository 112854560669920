#sidenav {
    .sidenav-logo-sprintpay {
        height: 40px;
        background: url('~src/assets/images/sprintpay-horizontal-logo.svg') 0 0 / contain no-repeat;
    }

    .sidenav-logo-agi {
        height: 40px;
        background: url('~src/assets/images/agi-logo.svg') 0 0 / contain no-repeat;
    }

    .admin__toolbar--switch-profile_mobile {
        display: none;

        @include media-breakpoint-down(lg) {
            display: block;
        }
    }

    .admin__toolbar--branch-selector_mobile {
        display: none;

        @include media-breakpoint-down(lg) {
            display: block;
        }
    }

    mat-list {
        .mat-expansion-panel-header {
            font: unset;
            height: 55px;
        }

        .mat-expansion-panel-body {
            padding: 0;
        }

        span,
        div {
            font-size: 14px;
            font-weight: 450px;
            letter-spacing: 0.1px;
            line-height: 24px;
            cursor: pointer;

            &.list-section-header {
                font-weight: $weight__regular;
                height: 55px;
                color: $gray-color;

                mat-icon {
                    height: 20px;
                    width: 20px;
                    font-size: 20px !important;
                }

                &__action {
                    &:hover,
                    &.active {
                        background-color: $primary-color-opacity-15;
                        color: $primary-color;
                        border-radius: 0;

                        mat-icon {
                            color: $primary-color;
                        }
                    }
                }
            }
        }

        mat-list-item {
            font-size: 1rem !important;
            height: 55px;
            color: $gray-color;

            mat-icon {
                height: 20px;
                width: 20px;
                font-size: 20px !important;
            }

            &:hover,
            &.active {
                background-color: $primary-color-opacity-15;
                color: $primary-color;
                border-radius: 4px;

                mat-icon,
                span {
                    color: $primary-color;
                }
            }
        }
    }
}
