#commonfacility_flux {
    &--container {
        background-color: $color-white;
        border-radius: 2px;
        padding: 0 24px 32px 24px;

        .awb {
            span {
                font-size: 12px;
                line-height: 13px;
                color: #7d7c7c;
                letter-spacing: normal;
            }
        }

        .amount {
            span {
                font-size: 10px;
                line-height: 16px;
                color: #7d7c7c;
                letter-spacing: 1.5px;
            }
        }

        h6 {
            &.step_section {
                font-size: 20px !important;
                font-weight: 500 !important;
                letter-spacing: 0.15px !important;
                line-height: 24px !important;
            }
        }

        .mat-form-field-infix {
            display: flex !important;
        }

        .payment-instructions {
            border-radius: 9px;
            background-color: $grey-color-5;
            padding: 20px;

            &-download-csv {
                color: $primary-color;
                font-family: $family__body;
                font-size: 1em;
                background: transparent;
                cursor: pointer;
                margin-top: 14px;
            }
        }

        .drop-zone-area {
            border-radius: 5px;
            border: dashed 2px #c1c2c4;
            padding: 2rem 1rem;
            height: auto;
            margin-bottom: 5px;

            .hide {
                display: none !important;
            }

            p {
                color: $primary-color !important;
            }

            i {
                vertical-align: bottom;
                color: $primary-color !important;
                margin-right: 5px;
                font-size: 1.5rem;
            }

            .upload-button {
                position: relative;
                height: 50px;
                transition: 0.3s;
                border: 1px solid $primary-color;
                color: $primary-color !important;
                font-weight: $weight__bold;
                width: 25%;
                padding: 0 2rem !important;
                border-radius: 4px;
                line-height: 30px;
                text-align: center;

                @include media-breakpoint-down(lg) {
                    width: 50%;
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                input {
                    opacity: 0 !important;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }
        }

        .payment-uploaded-csv {
            border: 1px solid $grey-color-20;
            border-radius: 9px;
            padding: 14px 0;

            .file-icon {
                img {
                    height: 60px;
                    width: 45px;
                }
            }

            .file-info-area {
                width: 60%;

                .file-info-area-body_left {
                    flex: 1;

                    .file-name {
                        color: $grey-color-100;
                        font-family: $family__body;
                        font-size: 1em;
                        line-height: 17px;
                        margin-left: 16px;
                        padding-bottom: 6px;
                    }

                    .file-size {
                        color: $grey-color-60;
                        font-family: $family__body;
                        font-size: 0.75em;
                        line-height: 13px;
                        margin-left: 16px;
                        margin-bottom: -6px;
                    }
                }

                .progress {
                    height: 16px;
                    border: 1px solid $grey-color-5;
                    margin: 10px 16px;
                    width: 96%;

                    .progress-bar {
                        background-color: $primary-color;
                    }
                }
            }
        }

        .flux-container {
            margin: 1rem 1rem 2rem 1rem;
            padding: 0.5rem;
        }
    }

    #facility_data {
        border-radius: 3px;
        background-color: #edfaf7;

        label {
            font-size: 14px;
            font-weight: 500 !important;
            line-height: 16px;
            margin-bottom: 12px;
        }

        h6 {
            font-size: 20px !important;
            font-weight: 500 !important;
            letter-spacing: 0.15px !important;
            line-height: 24px !important;
        }

        p {
            color: $grey-color-100;
            font-size: 14px;
            letter-spacing: 0.4px;
            line-height: 16px;
        }

        span {
            &.facilities-data--type {
                color: $grey-color-100;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                align-items: center;
                letter-spacing: 0.4px;

                mat-icon {
                    height: 20px;
                    width: 20px;
                    border-radius: 3px;
                    background-color: #f2880f;
                    color: $color-white;
                    font-size: 18px;
                }
            }
        }
    }
    .cancel-button {
        display: flex;
        color: #ba1e27;
        background-color: transparent;
        border: none !important;
        font-weight: 500;
        font-size: 14px;
        line-height: 16.41px;
        letter-spacing: 0.25px;
        min-height: 36px;
        padding-right: 8px;
        padding-left: 6px;
        gap: 10px;
        &:hover {
            background-color: transparent !important;
        }
        .mat-flat-button-focus-overlay {
            background-color: transparent;
        }
    }
}
