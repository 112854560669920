#branch-credit-details {
    padding: 24px;
    min-width: 250px;

    .header {
        margin-bottom: 40px;
        img {
            width: 28px;
            height: 28px;
        }

        p {
            color: rgba(0, 0, 0, 1);
            font-size: 30px;
            font-weight: 400;
            letter-spacing: 0.25px;
            text-align: left;
        }
    }

    .spinner-container {
        strong {
            color: rgba(33, 33, 33, 1);
            letter-spacing: 0.23px;
        }

        .mat-progress-spinner svg circle {
            stroke: #007e59 !important;
            opacity: 1 !important;
        }

        .spinner-background {
            width: 160px !important;
            height: 160px !important;
            border-color: rgb(216, 216, 216) !important;
            line-height: 80px;
            border-radius: 50%;
            border-style: solid;
            border-width: 15px;
        }
    }

    .sprintpay-credit {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.53px;
        text-align: center;
        margin-top: 32px;
        margin-bottom: 40px;
        align-self: center;

        span {
            font-weight: 500;
        }

        span,
        p {
            color: rgba(92, 91, 91, 1);
        }
    }

    .credit-limit-label p {
        color: rgba(92, 91, 91, 1);
    }

    .address {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.53px;
        text-align: left;
        margin-bottom: 32px;

        .address-title {
            font-weight: 500;
        }

        span,
        p {
            color: rgba(92, 91, 91, 1);
        }
    }
}

@media screen and (min-width: 500px) {
    #branch-credit-details {
        min-width: 392px;
    }
}

.cdk-overlay-pane:has(#branch-credit-details) {
    overflow-y: auto;
}
