/*********************************************************************************
*   SECTION: INVOICES LIST
*********************************************************************************/

section {
    &#invoices-list {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;

        .list__section--title {
            color: rgba(0, 0, 0, 0.87);
            font-family: $family__headers;
            font-size: 20px;
            font-weight: 500;
            letter-spacing: 0.15px;
            line-height: 24px;
        }

        .list__section--items {
            color: $grey-color-100;
            font-size: 14px;
            letter-spacing: 0.25px;
            line-height: 20px;
        }

        .list__section--deleteAll {
            top: -1.5rem;
            color: $grey-color-10;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 1.25px;
            line-height: 16px;
            min-width: 83px;
        }
        mat-accordion {
            margin: 0 3px;
        }

        mat-expansion-panel-header {
            margin-bottom: 1rem !important;
            font-size: 15px !important;
            background-color: $primary-color-50 !important;
            height: 100%;
        }

        .invoices-list__title {
            color: $grey-color-60;
            font-size: 12px;
            letter-spacing: 0.4px;
            line-height: 16px;
        }

        .invoices-list__value {
            color: rgba(0, 0, 0, 0.9);
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 19px;
            white-space: pre-line;
            word-wrap: break-word;
            max-width: 300px;
            overflow: hidden;
        }

        .invoices-list__btn--edit {
            color: #7d7c7c;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 1.25px;
            line-height: 16px;
            text-align: left;
            display: inline-block;
        }

        .invoices-list__btn--delete {
            color: $grey-color-10;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 1.25px;
            line-height: 16px;
            text-align: left;
            display: inline-block;
        }

        .awb-item-fee {
            position: relative;
            text-align: end;
            font:
                400 12px/20px Roboto,
                Open Sans,
                sans-serif;

            .item-fee-title {
                position: relative;
                text-align: left;
                letter-spacing: 0.25px;
            }

            .item-fee-value {
                letter-spacing: 0.15px;
                text-align: right;
            }
        }

        mat-list-item {
            &.mat-list-item {
                .mat-line {
                    font-size: 16px;

                    .invoices-list__main {
                        color: $grey-color-100;
                        font-family: $family__body;
                        font-size: 14px;
                        letter-spacing: 0.1px;
                        line-height: 24px;
                    }

                    .invoices-list__title {
                        color: $grey-color-60;
                        font-size: 12px;
                        letter-spacing: 0.4px;
                        line-height: 16px;
                    }

                    .invoices-list__value {
                        color: rgba(0, 0, 0, 0.9);
                        font-size: 15px;
                        font-weight: bold;
                        letter-spacing: 0;
                        line-height: 19px;
                        white-space: pre-line;
                        word-wrap: break-word;
                        max-width: 300px;
                        overflow: hidden;
                    }

                    .invoices-list__btn--edit {
                        color: #7d7c7c;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 1.25px;
                        line-height: 16px;
                        text-align: left;
                        display: inline-block;
                    }

                    .invoices-list__btn--delete {
                        color: $grey-color-10;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 1.25px;
                        line-height: 16px;
                        text-align: left;
                        display: inline-block;
                    }
                }
            }
        }
        .divider {
            width: calc(100% - 32px);
            display: block;
            margin: 0 auto;
        }
    }
}
