/*********************************************************************************
*   SECTION: ADMIN GRID
*********************************************************************************/

#admin {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .admin--mobile {
        .admin__toolbar {
            position: fixed;
            z-index: 2;
        }

        .admin__container {
            flex: 1 0 auto;
        }
    }

    .admin__container {
        flex: 1;

        mat-sidenav {
            &.sidenav__menu {
                min-width: 240px;
                max-width: 240px;
                box-shadow: 1px 0 20px $overlay-sidenav;

                &.sidnav__end {
                    max-width: 400px;
                }

                @include media-breakpoint-down(sm) {
                    min-width: 80%;
                    max-width: 80%;
                }
            }
        }

        mat-sidenav-content {
            background-color: #f4f5f7;

            mat-toolbar {
                font-size: 1em;
                background-color: #f4f5f7;
                position: sticky;
                top: 0;
                z-index: 100;

                .admin__toolbar--switch-profile {
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }

                .admin__toolbar--branch-selector {
                    height: 48px;

                    .custom-form-field {
                        height: 40px;
                        align-self: center;

                        &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
                            transform: translateY(-1em) scale(0.75);
                        }
                    }

                    .mat-divider {
                        height: 34px;
                        align-self: center;
                    }
                    .mat-mdc-text-field-wrapper {
                        height: 40px;
                        margin: 0;
                        padding-bottom: 0;

                        .mat-mdc-form-field-flex {
                            height: 40px;

                            .mat-mdc-form-field-infix {
                                padding-top: 3px;
                                align-content: center;

                                .mat-form-field-label {
                                    top: 18px;
                                }
                            }
                        }
                    }
                }

                button {
                    &.admin__toolbar--btnPayment {
                        min-width: 150px;
                        margin-left: 10px;
                        @include media-breakpoint-down(md) {
                            display: none !important;
                        }

                        &.subtle-white-border {
                            border: 1px solid rgba($color-white, 0.5) !important;
                        }
                    }

                    &.admin__toolbar--btnCart,
                    &.admin__toolbar--btnMenu {
                        .mat-badge-content {
                            color: $color-white;
                        }

                        @include media-breakpoint-down(lg) {
                            color: $color_white;
                        }
                    }
                }

                .spacer {
                    flex: 1 1 auto;
                }

                @include media-breakpoint-down(lg) {
                    background-color: $primary-color;
                }
            }

            #admin__main {
                color: $text-color-light;
                line-height: 24px;
                font-weight: 300;

                .admin--switch-profile_mobile {
                    display: none;
                    @include media-breakpoint-down(lg) {
                        display: block;
                        padding: 1.5rem 0 0 1.5rem;
                    }
                }
            }
        }
    }
}

.single-portal {
    display: flex;
    padding: 12px;

    .admin__toolbar--letter {
        background-color: $primary-color;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        cursor: pointer;
        flex-shrink: 0;

        span {
            color: $color-white;
            height: 40px;
            line-height: normal;
        }
    }

    .admin__toolbar--profile {
        span {
            font-size: $size__normal;
            display: block;
            letter-spacing: 0.5px;
            line-height: 24px;

            &:first-child {
                font-weight: $weight__regular;
            }

            &:last-child {
                font-size: 12px;
                color: $gray-color;
                font-weight: $weight__light;
                display: block;
                word-break: break-all;
            }
        }
    }
}

.branch-selector {
    .item-border {
        border-bottom: 1px solid #dedede;
        color: $primary-color;
    }
}

.credit-card-hold {
    background-color: $warm-color-lighter;
    box-shadow: 0 1px 4px 0 $containers-color-opacity !important;
    padding: 10px 10px;
    margin-bottom: 20px !important;
    border-left: 2px solid $warm-color;
    border-radius: 0.375rem;
    color: $text-color-light !important;

    a {
        color: $text-color-light !important;
    }
}

.close-banner-button {
    color: $text-color-light !important;
    background-color: transparent;
    opacity: 100;
    float: right;
}

.exclamation-triangle-warm {
    color: $warm-color;
}

.exclamation-icon-large {
    font-size: 1.5rem;
}
