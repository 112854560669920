.inverted-color .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(3, 39, 71, 0.5);
}

.inverted-color .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    box-shadow:
        0px 2px 1px -1px rgba(3, 39, 71, 0.2),
        0px 1px 1px 0px rgba(3, 39, 71, 0.14),
        0px 1px 3px 0px rgba(3, 39, 71, 0.12);
    background-color: #fafafa;
}

.inverted-color .mat-slide-toggle-bar {
    background-color: rgba(20, 187, 156, 0.54);
}

.inverted-color .mat-slide-toggle-thumb {
    background-color: #14bb9c;
}
