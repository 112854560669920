/*********************************************************************************
*   SECTION: TIMELINE
*********************************************************************************/
mat-dialog-container {
    &#timeline.mdc-dialog {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
        left: unset;
        padding: 24px;
        border-radius: 0 !important;
        width: 25% !important;
        background: $background-color-light !important;
        height: 100% !important;
        max-height: 100% !important;

        @include media-breakpoint-down(lg) {
            width: 50% !important;
        }

        @include media-breakpoint-down(md) {
            width: 75% !important;
        }

        .mat-dialog-content {
            max-height: fit-content !important;
        }

        .mdc-dialog__surface {
            box-shadow: unset;
            background: $background-color-light;
        }

        p {
            &#title-timeline,
            &#items-timeline {
                letter-spacing: 0.15px;
                line-height: 24px;
            }

            &#items-timeline {
                font-weight: $weight__bold;
            }

            &#title-timeline {
                color: $gray-color;
            }
        }

        .timeline {
            border-left: 3px solid $text-color-light;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
            padding: 50px 10px 10px 30px;
            list-style: none;

            .event {
                margin-bottom: 25px;
                border: none !important;

                .dark-text {
                    font-weight: 600;
                }

                .card-text {
                    &.space-pre-wrap,
                    .space-pre-wrap {
                        white-space: pre-wrap;
                    }

                    .mat-icon {
                        font-size: 18px;
                    }

                    .event__voided {
                        color: $warning-color;
                    }

                    .event__rejected {
                        color: $error-color;
                    }

                    .event__created {
                        color: $success-color;
                    }

                    .event__approved {
                        color: $success-color;
                    }

                    .event__edited {
                        color: $info-color;
                    }

                    .event__paid {
                        color: $success-color;
                    }

                    .event__toCart {
                        color: $info-color;
                    }
                }
                .icon-flag {
                    color: $info-color-500;
                }
                &:last-of-type {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border: none;
                }

                &:before,
                &:after {
                    position: absolute;
                    display: block;
                    top: 0;
                }

                &:before {
                    left: -207px;
                    content: attr(data-date);
                    text-align: right;
                    font-weight: 100;
                    font-size: 0.9em;
                    min-width: 120px;
                }

                &:after {
                    -webkit-box-shadow: 0 0 0 3px $text-color-light;
                    box-shadow: 0 0 0 3px $text-color-light;
                    left: -35.8px;
                    background: $color-white;
                    border-radius: 50%;
                    height: 9px;
                    width: 9px;
                    content: '';
                    top: 5px;
                }
            }
        }

        button {
            span {
                &.mat-flat-button-focus-overlay {
                    background: none !important;
                }
            }
        }
    }
}
