#echecks-modal {
    padding: 10px 55px 58px 55px;

    #echecks-list {
        mat-radio-button {
            padding: 24px 0;
            border-bottom: 1px solid rgba(235, 235, 235, 1);
        }
    }

    mat-icon {
        top: 10px;
        right: 12px;
    }
}

.mat-dialog-container:has(app-echecks-modal) {
    padding: 0;
}

@media screen and (min-width: 1200px) {
    #echecks-modal {
        #echecks-list {
            min-width: 760px;
        }
    }
}
