.letter-spacing span {
    letter-spacing: 1.25px;
}

app-debit-account-card {
    .debit-account-card {
        background-color: #f7f7f7;
        padding: 24px 20px;
        max-width: 528px;

        &.isMain {
            background-color: #edfaf7;
            color: #14bb9c;
            border-style: solid;

            .main-label {
                color: #14bb9c;
            }
        }

        .buttons {
            button {
                background-color: transparent;
            }

            .button-edit {
                color: #009b75;
            }
        }
    }
}

.detail-card {
    background-color: #dcf3ee;
}

#account-debit-banner {
    background-color: #edfaf7;
}

.green-border {
    border: 2px solid #009b75 !important;
    color: #009b75;
}

.email-control,
.email-control > mat-form-field {
    display: block;
    max-width: 420px;
}

.buttons {
    button {
        min-width: 160px;

        &.btn-confirm {
            background-color: #009b75;
            color: white;
        }

        &.btn-cancel {
            color: #009b75;
            border: 2px solid #009b75;
        }
    }
}

.mw-unset {
    min-width: unset !important;
}
