.shipment-information-section {
    // background-color: $primary-color-50;
    .flight-information-header {
        background-color: #f7f7f7;
        button {
            color: $primary-color;
        }

        .flight-information-data {
            font-size: 17px;
            .divider {
                height: 20px;
            }
        }
    }
    .shipment-information-header {
        // background-color: #007e59;
        min-height: 24px;
        // color: $color-white;
        .text {
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
        }
    }
    .shipment-information {
        min-height: 50px;
        border-bottom: $gray-color;
        .shipment-information-title {
            letter-spacing: 0.4%;
            font-size: 12px;
            margin: 0;
            line-height: 12px;
            color: $gray-color;
        }

        .shipment-information-description {
            letter-spacing: 0.5px;
            margin: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            color: $color-black;
        }
    }

    .address-info {
        letter-spacing: 0.15px;
        font-weight: bold;
        color: $primary-color;
    }
}
