#download {
    &__modal {
        background-color: $color-white;

        .header {
            span {
                padding: 0;
                margin: 0;

                button {
                    background-color: $color-white;
                    color: $primary-color;
                }

                &.isActive {
                    border-bottom: 2px solid $primary-color;
                    color: $primary-color;
                }
            }
        }

        .information {
            background-color: #f8f8f8;
            border-radius: 4px;
            font-size: 1rem;
            color: rgba($color: #000000, $alpha: 0.87);

            .title {
                font-weight: 800;
            }
        }
    }

    &__actions {
        button {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}
