#refund-request {
    @include media-breakpoint-up(md) {
        max-height: none;
        overflow: hidden;
    }
    &__modal {
        background-color: $color-white;

        .refund-logo {
            padding: 0px 100px 10px 100px;

            @include media-breakpoint-down(md) {
                padding: 24px;
            }
        }

        .header {
            h4 {
                font-size: 1.875em;
            }
        }

        .sub-header {
            margin-bottom: 1em;
            background-color: $primary-color-50;
            border-radius: 4px;

            .reference-number {
                padding: 10px;

                h6 {
                    font-size: 1.3125em !important;
                    color: $grey-color-100;
                    font-weight: 400 !important;
                }

                p {
                    font-family: $family__body;
                    font-size: 1em;
                    color: $primary-color;
                    font-weight: 700;
                }
                .info {
                    font-size: 12px;
                }
            }
            .refund-fee {
                border-left: 3px solid #1976d2;
                padding: 1rem;
                p {
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: 0.5;
                }
                .title {
                    display: flex;
                    align-self: end;
                }
                .info {
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0.15;
                    width: 300px;
                }
                mat-icon {
                    font-size: 18px;
                    color: #2196f3;
                }
            }
        }
    }

    &__actions {
        button {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}
