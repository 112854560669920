/*********************************************************************************
*   SECTION: GUEST SIGNUP FORM
*********************************************************************************/

#guest-signup-form {
    section {
        margin-top: 2rem;
    }

    span.subTitle {
        margin-bottom: 1.5rem;
        display: block;
        color: #7d7c7c;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 1.5;
        text-transform: uppercase;
    }
}
