/*********************************************************************************
*   SECTION: REJECTED REQUEST
*********************************************************************************/

#rejected-request {
    &__header {
        h6 {
            font-weight: $weight__medium !important;
            margin: 0 1rem 0.2rem 0;
        }

        button {
            &.rejected-request__all {
                padding: 0 !important;
            }
        }
    }

    &__container {
        flex-wrap: wrap;

        table.mat-table,
        .card {
            background-color: $color-white;
            box-shadow: 0 1px 4px 0 $containers-color-opacity !important;

            span {
                &.title {
                    font-size: 1rem;
                    font-family: $family__headers;
                    height: 1.5rem;
                    letter-spacing: 0.15px;
                }

                &.subtitle {
                    font-family: $family__body;
                    color: $gray-color-darker;
                    font-size: 0.75rem;
                    height: 1rem;
                    letter-spacing: 0.4px;
                }
            }

            .rejected-notes {
                background: rgba(247, 39, 59, 0.03);
                padding-left: 1.5rem;
                padding-right: 1.5rem;

                .title {
                    font-family: $family__body;
                    color: $gray-color-darker;
                    font-size: 0.75rem;
                    letter-spacing: 0.4px;
                }

                .notes {
                    font-family: $family__body;
                    color: $gray-color;
                    font-size: 0.75rem;
                    letter-spacing: 0.4px;
                }
            }

            .updatePayment {
                button {
                    font-family: $family__body;
                    color: $primary-color;
                    font-size: 14px;
                    letter-spacing: 1.25px;
                    font-weight: 500;

                    &.updatePayment_button {
                        padding: 0 !important;
                    }
                }
            }
        }
    }
}
