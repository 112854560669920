/*********************************************************************************
*   SECTION: CARD INVOICE
*********************************************************************************/

#frequent_facilities {
    background-color: $color-white;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 $containers-color-opacity;
    padding: 16px;

    h6 {
        line-height: 27px !important;
        font-weight: $weight__medium !important;
    }

    .image {
        @include media-breakpoint-down(md) {
            display: none !important;
        }
    }

    p {
        font-size: 1rem;
        font-weight: bold;
    }

    .facilities_item {
        h6 {
            font-size: 1em !important;
            line-height: 24px !important;
            font-weight: $weight__bold !important;
        }

        p {
            &.facilities_item--address {
                color: $gray-color;
                font-size: 0.875em;
                line-height: 21px;
                letter-spacing: 0.4px;
            }

            &.facilities_item--type {
                color: $info-color;
                font-size: 0.875em;
                font-weight: $weight__medium !important;
                line-height: 21px;
                align-items: center;
                letter-spacing: 0.4px;

                mat-icon {
                    height: 20px;
                    width: 20px;
                    border-radius: 3px;
                    background-color: #f2880f;
                    color: $color-white;
                    font-size: 18px;
                }
            }
        }

        button {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}
