/*********************************************************************************
*   SECTION: CARD WALLET
*********************************************************************************/

#card-wallet {
    .card {
        background-color: $color-white;
        box-shadow: 0 1px 4px 0 $containers-color-opacity !important;

        img {
            width: 40%;
            max-width: 250px;
        }

        .progress {
            width: 100% !important;

            .progress-bar {
                background-color: $info-color;
                font-weight: 500;
            }
        }

        .information {
            .title {
                font-family: $family__body;
                font-size: 12px;
                letter-spacing: 0;
            }

            .subtitle {
                font-family: $family__headers;
                font-size: 1rem;
                letter-spacing: 0.15px;
            }
        }

        .mat-progress-spinner circle,
        .mat-spinner circle {
            stroke: $info-color;
        }

        :not(spinner-container).spinner-container {
            position: relative;

            .mat-progress-spinner circle,
            .mat-spinner circle {
                stroke: $info-color;
            }

            .spinner-background {
                width: 120px;
                height: 120px;
                line-height: 80px;
                border-color: rgba(103, 58, 183, 0.12);
                border-radius: 50%;
                border-style: solid;
                border-width: 15px;
            }
        }

        mat-progress-spinner {
            svg {
                circle {
                    stroke: $info_color !important;
                    opacity: 1 !important;
                }
            }
        }
    }

    @media (min-width: 320px) {
        .card {
            width: unset !important;
        }
    }

    @media (min-width: 1024px) {
        .card {
            width: max-content !important;
        }
    }
}
