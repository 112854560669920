@use '@angular/material' as material;
@use 'sass:map';

ng-select {
    padding-bottom: 0 !important;

    &.ng-select-focused {
        .ng-select-container {
            &:after {
                border-color: rgba($primary-color-lighter, 0.5) !important;
            }
            &.ng-appearance-outline {
                &:after,
                &:hover:after {
                    border-color: rgba($primary-color-lighter, 0.5) !important;
                }
            }
            .ng-value-container .ng-placeholder {
                transform: translateY(-1.38125em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
                color: rgba($primary-color-lighter, 0.5) !important;
            }
            .ng-value-container .ng-input {
                display: flex;
            }
            .ng-arrow-wrapper .ng-arrow {
                color: rgba($primary-color-lighter, 0.5) !important;
            }
        }
    }

    .ng-select-container {
        &.ng-appearance-outline {
            &:after {
                border-width: 1px !important;
            }
        }
        .ng-value-container {
            .ng-value {
                padding-left: 8px !important;

                .ng-value-label {
                    font-size: 16px !important;
                }
            }
            .ng-input {
                top: 25px;
                padding-left: 16px;
            }
        }
        .ng-value.ng-star-inserted {
            padding-top: 5px;
        }
        .ng-placeholder {
            top: 24px;
            font-size: 16px !important;
            padding-left: 10px !important;
            color: rgba(0, 0, 0, 0.6) !important;
            background-color: #f7f7f7 !important;
        }
    }

    .ng-has-value,
    &.ng-select-opened {
        .ng-placeholder {
            color: rgba(0, 0, 0, 0.6) !important;
            transform: translateY(-1.38125em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
        }
    }

    .ng-dropdown-panel-items {
        .ng-optgroup {
            &.ng-option-selected {
                color: rgba($primary-color-lighter, 0.5) !important;
            }
        }
        .ng-option {
            &.ng-option-selected {
                color: rgba($primary-color-lighter, 0.5) !important;
            }
        }
    }

    .ng-dropdown-panel {
        &.ng-select-bottom {
            top: calc(100% - 0.15em);
        }
        &.ng-dropdown-panel-items .ng-option.ng-option-selected {
            color: rgba($primary-color-lighter, 0.5) !important;
        }
    }
}
