/*********************************************************************************
*   SECTION: FORGOT PASSWORD
*********************************************************************************/

#forgot-password {
    .existing__account {
        color: $text-color-light;

        &--link {
            color: $primary-color;

            &:hover {
                text-decoration: underline !important;
            }
        }
    }
}
