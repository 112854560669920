#filter-by-column {
    padding: 1px 11px 0px 29px;

    .title {
        font-family: 'Mulish', sans-serif;
        font-size: 21px;
        font-weight: 400;
        font-style: normal;
        text-align: left;
        letter-spacing: 0.5px;
        line-height: normal;
    }

    .list-container {
        .item-header {
            color: rgba(125, 124, 124, 1);
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0px;
            text-align: left;
            line-height: 8.5px;
            text-transform: uppercase;
        }
        .item {
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 400;
            font-style: normal;
            letter-spacing: 0.5px;
            text-align: left;
        }
    }
}
