/*********************************************************************************
*   SECTION: PAYMENT METHODS
*********************************************************************************/

#newPaymentMethods {
    &__header {
        h6 {
            font-weight: $weight__medium !important;
            margin: 0 1rem 0.2rem 0;
        }
    }

    &__container {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;

        @include media-breakpoint-down(md) {
            padding: 1.5rem !important;
        }

        .creditCard {
            .swal2-popup {
                width: 45em !important;
            }

            .creditCardIcon {
                position: absolute;
                right: 2px;
                top: 2px;
                margin: 1px;

                img {
                    border-radius: 2px;
                    border: none !important;
                    height: 33px;
                    width: 55px;
                }
            }

            .panel {
                width: 60%;
                margin: 0 auto;
                margin-bottom: 50px;
                text-align: center;
            }

            .creditCardTemplate {
                width: 100%;
                max-width: 540px;

                .header {
                    border-radius: 15px;
                    position: relative;
                    text-align: center;
                    background: linear-gradient(60deg, rgb(30, 136, 229) 0%, rgb(20, 187, 156) 100%);
                    color: white;

                    .inner-header {
                        height: 175px;

                        .card__number {
                            font-size: 40px;
                            position: absolute;
                            left: 33px;
                            top: 160px;
                            font-weight: 500;
                            letter-spacing: 12px;
                        }

                        .card__expiry-date {
                            font-size: 20px;
                            font-weight: 400;
                            position: absolute;
                            right: 33px;
                            bottom: 40px;
                            padding: 0;
                        }

                        .card__owner {
                            font-size: 30px;
                            font-weight: 400;
                            position: absolute;
                            left: 33px;
                            bottom: 43px;
                            width: calc(100% - 150px);
                            white-space: normal;
                            word-wrap: break-word;
                            height: auto;
                            text-align: left;
                            line-height: 30px;
                            letter-spacing: 1px;
                        }

                        .card__logo {
                            position: absolute;
                            right: 33px;
                            top: 45px;
                        }
                    }

                    .waves {
                        position: relative;
                        width: 100%;
                        height: 15vh;
                        margin-bottom: -7px; /*Fix for safari gap*/
                        min-height: 100px;
                        max-height: 150px;
                        opacity: 0.5;
                    }

                    /* Animation */

                    .parallax > use {
                        animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
                    }

                    .parallax > use:nth-child(1) {
                        animation-delay: -2s;
                        animation-duration: 7s;
                    }

                    .parallax > use:nth-child(2) {
                        animation-delay: -3s;
                        animation-duration: 10s;
                    }

                    .parallax > use:nth-child(3) {
                        animation-delay: -4s;
                        animation-duration: 13s;
                    }

                    .parallax > use:nth-child(4) {
                        animation-delay: -5s;
                        animation-duration: 20s;
                    }

                    @keyframes move-forever {
                        0% {
                            transform: translate3d(-90px, 0, 0);
                        }
                        100% {
                            transform: translate3d(85px, 0, 0);
                        }
                    }
                    /*Shrinking for mobile*/
                    @media (max-width: 768px) {
                        .waves {
                            height: 40px;
                            min-height: 40px;
                        }
                        .content {
                            height: 30vh;
                        }
                        h1 {
                            font-size: 24px;
                        }
                    }
                }
            }
        }

        .eCheck {
            .creditCardIcon {
                position: absolute;
                right: 2px;
                top: 2px;
                margin: 1px;

                img {
                    border-radius: 2px;
                    border: none !important;
                    height: 33px;
                    width: 55px;
                }
            }

            .panel {
                width: 65%;
            }

            .creditCardTemplate {
                width: 100%;
                max-width: 540px;

                .header {
                    border-radius: 15px;
                    position: relative;
                    text-align: center;
                    background: linear-gradient(60deg, rgb(30, 136, 229) 0%, rgb(20, 187, 156) 100%);
                    color: white;

                    .inner-header {
                        height: 175px;

                        .card__number {
                            font-size: 30px;
                            position: absolute;
                            left: 33px;
                            top: 170px;
                            font-weight: 400;
                            text-align: left;
                            letter-spacing: 12px;
                        }

                        .card__expiry-date {
                            font-size: 30px;
                            font-weight: 400;
                            position: absolute;
                            left: 33px;
                            top: 230px;
                            padding: 0;
                            letter-spacing: 12px;
                        }

                        .card__owner {
                            font-size: 40px;
                            font-weight: 500;
                            position: absolute;
                            left: 33px;
                            top: 60px;
                            width: calc(100% - 50px);
                            white-space: normal;
                            word-wrap: break-word;
                            height: auto;
                            text-align: left;
                            line-height: 40px;
                            letter-spacing: 1px;
                        }
                    }

                    .waves {
                        position: relative;
                        width: 100%;
                        height: 15vh;
                        margin-bottom: -7px; /*Fix for safari gap*/
                        min-height: 100px;
                        max-height: 150px;
                        opacity: 0.5;
                    }

                    /* Animation */

                    .parallax > use {
                        animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
                    }

                    .parallax > use:nth-child(1) {
                        animation-delay: -2s;
                        animation-duration: 7s;
                    }

                    .parallax > use:nth-child(2) {
                        animation-delay: -3s;
                        animation-duration: 10s;
                    }

                    .parallax > use:nth-child(3) {
                        animation-delay: -4s;
                        animation-duration: 13s;
                    }

                    .parallax > use:nth-child(4) {
                        animation-delay: -5s;
                        animation-duration: 20s;
                    }

                    @keyframes move-forever {
                        0% {
                            transform: translate3d(-90px, 0, 0);
                        }
                        100% {
                            transform: translate3d(85px, 0, 0);
                        }
                    }
                    /*Shrinking for mobile*/
                    @media (max-width: 768px) {
                        .waves {
                            height: 40px;
                            min-height: 40px;
                        }
                        .content {
                            height: 30vh;
                        }
                        h1 {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}
