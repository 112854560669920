/*********************************************************************************
*   SECTION: ACCOUNT TYPE INDIVIDUAL
*********************************************************************************/

#account-type-individual {
    .account-type-individual__container {
        background-image: url('/assets/images/signup/individual.svg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: bottom right;
        background-size: 250px 250px;
        background-position-x: calc(100% - 16px);
        background-position-y: calc(100% - 16px);

        @include media-breakpoint-down(lg) {
            background-image: none;
        }

        .mat-toolbar-single-row {
            padding: 0 20px;

            @include media-breakpoint-down(lg) {
                padding: 0;
            }
        }

        button {
            &.btn__stepper--first {
                @include media-breakpoint-down(lg) {
                    margin-bottom: 1em;
                }
            }
        }

        .existing__account {
            color: $text-color-light;

            &--link {
                color: $primary-color;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }
}
