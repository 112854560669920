/*********************************************************************************
*   SECTION: PAYMENTS DETAIL
*********************************************************************************/

#payment-summary {
    .stepper-button-group {
        @include media-breakpoint-down(lg) {
            display: block !important;

            button {
                width: 100%;
            }

            .btn__sprintpay--cart {
                white-space: normal;
                word-wrap: break-word;
                height: auto;
            }
        }
    }
}

.payment-summary__sections {
    background: $primary-color-opacity-15 !important;

    a {
        color: $primary-color;
        font-weight: $weight__regular;
        letter-spacing: 1.25px;
    }
}

p {
    color: $text-color-light;

    &.card-subtitle {
        color: $gray-color;
    }
}

button {
    &.addToCartAndRequest {
        span {
            display: block;
            line-height: 24px;
        }
    }
}
