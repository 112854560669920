/*********************************************************************************
*   SECTION: INVOICES LIST
*********************************************************************************/

section {
    &#payment-detail {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;
        color: #000;
        /*
    height: calc(100vh - 237px);
    min-height: 250px;
    max-height: calc(100vh - 237px);
     */

        .payment-detail--titles {
            letter-spacing: 0.15px;
            line-height: normal;
            color: #000;
        }

        .payment-detail--method {
            font-size: 16px;
            letter-spacing: 0.2px;
            line-height: 23px;

            img {
                height: 21px;
                width: 32px;
            }

            .imgMain {
                height: 39px !important;
                width: 50px !important;
            }
        }

        button {
            &.payment-detail--methodChange {
                text-align: left;
                letter-spacing: 1.25px;
                line-height: 16px;
                padding: 0 !important;
                white-space: break-spaces;
            }

            &.payment-detail--guestCardChange {
                letter-spacing: 1.25px;
                line-height: 16px;
                padding: 0 !important;
                white-space: break-spaces;
                width: unset;
            }
        }

        .payment-detail--split--titles {
            color: $grey-color-100;
            line-height: 20px;
        }

        .payment-detail--split--amounts {
            color: #101010;
            font-weight: 700 !important;
            letter-spacing: 0.15px;
            line-height: 19px;
            margin-bottom: 0 !important;

            @include media-breakpoint-down(md) {
                text-align: left !important;
                margin-bottom: 15px !important;
            }
        }

        .container-total {
            background-color: #f7f7f7;
            min-height: 61px;
            border-radius: 4px;
        }

        .payment-detail--total {
            position: absolute;
            right: 24px;
        }

        .payment-warning__msg {
            border-radius: 4px !important;
            background-color: rgba(229, 57, 53, 0.7);
            color: $color-white !important;
        }

        .terms-of-use-checkbox {
            margin-right: 8px;
        }

        #paypal-button.disabled {
            opacity: 50%;
        }

        .guest-payment-method-card {
            .mat-radio-label {
                white-space: break-spaces;
            }
        }
        .space {
            height: 8px;
        }
    }
}
.list-item{
    display: flex;
    flex-direction: row;    
    align-items: center;    
    align-content: center;
    flex-wrap: nowrap;
    width: 100%;
    padding: 12px 0px;
}
.echeck-information{
    display: flex;
    flex-direction: row;  
    align-items: center;    
    width: 100%;    
}
.echeck-buttons {    
    display: flex;
    flex-direction: row;  
    align-items: flex-end;
}
@media (max-width: 720px) {
    .list-item {
        display: flex;
        flex-direction: column;
        align-items: center;    
        align-content: center;
        flex-wrap: nowrap;
        width: 100%;        
        margin-bottom: 8px;
    }
    .echeck-buttons {
      display: flex;
      flex-direction: column;      
      align-items: center;
    }
  }