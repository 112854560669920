#switch-profile {
    .switch-icon {
        color: $primary-color;
    }

    mat-divider {
        height: 34px;
    }

    .switch-button {
        font-size: 14px;
        font-weight: 500;
        height: 34px;
    }

    .switch-profile {
        min-width: 250px !important;

        span {
            font-size: $size__normal;
            display: flex;
            letter-spacing: 0.5px;
            line-height: 24px;

            &:first-child {
                font-weight: $weight__regular;
            }

            &:last-child {
                font-size: 12px;
                color: $gray-color;
                font-weight: $weight__light;
                word-break: break-all;
            }

            .highlight {
                font-size: 16px;
                letter-spacing: 0.5px;
                line-height: 24px;
                color: $color-black;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        display: block !important;
        padding: 0 1rem !important;

        .switch-profile__wrapper {
            justify-content: flex-start !important;
        }

        .username-only {
            margin-left: -16px;
        }

        mat-divider {
            display: none;
        }

        .switch-button {
            text-align: left;
            margin-left: 12px;
            line-height: 15px;
            margin-top: 16px;

            mat-icon {
                width: 40px;
            }
        }

        .switch-profile {
            span {
                line-height: 18px !important;
            }
        }
    }
}

mat-form-field.select-portal {
    height: 48px;

    .mat-mdc-text-field-wrapper {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    @media screen and (min-width: 991.99px) {
        .mat-mdc-text-field-wrapper {
            height: 40px;

            .mat-mdc-form-field-flex {
                height: 40px;

                .mat-mdc-form-field-infix {
                    padding-top: 3px;
                    align-content: center;
                }
            }
        }
    }
}
