/*********************************************************************************
*   SECTION: ACCOUNT TYPE
*********************************************************************************/

#settings {
    &__header {
        h6 {
            font-weight: $weight__medium !important;
            margin: 0 1rem 0.2rem 0;
        }
    }

    &__container {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;

        @include media-breakpoint-down(md) {
            padding: 1.5rem !important;
        }

        ul {
            &.nav {
                li {
                    flex: 1 1 auto;

                    &.nav-item {
                        margin: 1em 0;
                        cursor: pointer;

                        span {
                            padding: 1em 0;
                            color: $gray-color;
                            border-width: 0 0 2px 0;
                            letter-spacing: 0.4px;
                            font-weight: $weight__medium;

                            &.active {
                                color: $primary-color;
                                border-bottom-color: $primary-color !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mat-tab-group {
    .mat-tab-header {
        margin: 1em 0 2em 0;
    }

    .mat-tab-label {
        text-transform: uppercase;
        letter-spacing: 0.4px;
        font-weight: $weight__medium;
        font-size: 16px;
        background-color: unset;
        opacity: 1;
        color: $gray-color;

        &-active {
            color: $primary-color;
        }
    }
    .mat-tab-body-content {
        overflow-x: hidden;
    }
}
