/*********************************************************************************
*   SECTION: HOME
*********************************************************************************/

#home {
    button {
        &.admin__toolbar--btnPayment {
            display: none !important;

            @include media-breakpoint-down(md) {
                display: block !important;
            }
        }
    }
    .heading {
        font-size: 2.3em;
        color: #032747;
        letter-spacing: normal;
        font-family: sans-serif;
    }
}
