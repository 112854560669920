/*********************************************************************************
*   SECTION: NEW PAYMENT
*********************************************************************************/

#search_facility {
    &__header {
        h6 {
            font-weight: $weight__medium !important;
            margin: 0 1rem 1rem 0;

            @include media-breakpoint-down(md) {
                margin-bottom: 0.2rem;
            }
        }
    }

    &__container {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;

        @include media-breakpoint-down(md) {
            padding: 1.5rem !important;
        }

        .image {
            @include media-breakpoint-down(md) {
                display: none !important;
            }
        }

        p {
            font-size: 1rem;
            font-weight: bold;
        }

        .facilities_item {
            h6 {
                font-size: 16px !important;
                letter-spacing: 0.15px !important;
                line-height: 24px !important;
                font-weight: $weight__medium !important;
            }

            p {
                &.facilities_item--address {
                    color: $gray-color;
                    font-size: 0.75em;
                    line-height: 24px;
                    letter-spacing: 0;
                    font-weight: $weight__regular !important;
                }

                &.facilities_item--type {
                    color: $info-color;
                    font-weight: $weight__medium !important;
                    align-items: center;
                    font-size: 0.75em;
                    line-height: 24px;
                    letter-spacing: 0;

                    mat-icon {
                        height: 20px;
                        width: 20px;
                        border-radius: 3px;
                        background-color: #f2880f;
                        color: $color-white;
                        font-size: 18px;
                    }
                }
            }

            button {
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
        }
    }
}

#standard_flux {
    &--container {
        background-color: $color-white;
        border-radius: 2px;

        .awb {
            span {
                font-size: 12px;
                line-height: 13px;
                color: #7d7c7c;
                letter-spacing: normal;
            }
        }

        .amount {
            span {
                font-size: 10px;
                line-height: 16px;
                color: #7d7c7c;
                letter-spacing: 1.5px;
            }
        }

        h6 {
            &.step_section {
                font-size: 20px !important;
                font-weight: 500 !important;
                letter-spacing: 0.15px !important;
                line-height: 24px !important;
            }
        }

        .mat-form-field-infix {
            display: flex !important;
        }

        .payment-instructions {
            border-radius: 9px;
            background-color: $grey-color-5;
            padding: 20px;

            &-download-csv {
                color: $primary-color;
                font-family: $family__body;
                font-size: 1em;
                background: transparent;
                cursor: pointer;
                margin-top: 14px;
            }
        }
        .form-field {
            @media (min-width: 320px) {
                width: unset !important;
            }
            @media (min-width: 768px) {
                width: 394px !important;
            }
        }
        .company-form-field {
            width: 734px !important;
        }
        .center-form {
            display: flex;
            justify-content: center;
        }
        .drop-zone-area {
            border-radius: 5px;
            border: dashed 2px #c1c2c4;
            padding: 2rem 1rem;
            height: auto;
            margin-bottom: 5px;

            .hide {
                display: none !important;
            }

            p {
                color: $primary-color !important;
            }

            i {
                vertical-align: bottom;
                color: $primary-color !important;
                margin-right: 5px;
                font-size: 1.5rem;
            }

            .upload-button {
                position: relative;
                height: 50px;
                transition: 0.3s;
                border: 1px solid $primary-color;
                color: $primary-color !important;
                font-weight: $weight__bold;
                width: 25%;
                padding: 0 2rem !important;
                border-radius: 4px;
                line-height: 30px;
                text-align: center;

                @include media-breakpoint-down(lg) {
                    width: 50%;
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                input {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }
        }

        .payment-uploaded-csv {
            border: 1px solid $grey-color-20;
            border-radius: 9px;
            padding: 14px 0;

            .file-icon {
                img {
                    height: 60px;
                    width: 45px;
                }
            }

            .file-info-area {
                width: 60%;

                .file-info-area-body_left {
                    flex: 1;

                    .file-name {
                        color: $grey-color-100;
                        font-family: $family__body;
                        font-size: 1em;
                        line-height: 17px;
                        margin-left: 16px;
                        padding-bottom: 6px;
                    }

                    .file-size {
                        color: $grey-color-60;
                        font-family: $family__body;
                        font-size: 0.75em;
                        line-height: 13px;
                        margin-left: 16px;
                        margin-bottom: -6px;
                    }
                }

                .progress {
                    height: 16px;
                    border: 1px solid $grey-color-5;
                    margin: 10px 16px;
                    width: 96%;

                    .progress-bar {
                        background-color: $primary-color;
                    }
                }
            }
        }

        .flux-container {
            margin: 1rem 1rem 2rem 1rem;
            padding: 0.5rem;
        }
    }

    #facility_data {
        border-radius: 3px;
        background-color: #edfaf7;

        label {
            font-size: 14px;
            font-weight: 500 !important;
            line-height: 16px;
            margin-bottom: 12px;
        }

        h6 {
            font-size: 20px !important;
            font-weight: 500 !important;
            letter-spacing: 0.15px !important;
            line-height: 24px !important;
        }

        p {
            color: $grey-color-100;
            font-size: 14px;
            letter-spacing: 0.4px;
            line-height: 16px;
        }

        span {
            &.facilities-data--type {
                color: $grey-color-100;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                align-items: center;
                letter-spacing: 0.4px;

                mat-icon {
                    height: 20px;
                    width: 20px;
                    border-radius: 3px;
                    background-color: #f2880f;
                    color: $color-white;
                    font-size: 18px;
                }
            }
        }
    }
}

#company_reference {
    &--container {
        background-color: $color-white;
        border-radius: 2px;

        .company-form-field {
            width: 734px !important;
        }
        .center-form {
            display: flex;
            justify-content: center;
        }
        h6 {
            &.step_section {
                font-size: 20px !important;
                font-weight: 500 !important;
                letter-spacing: 0.15px !important;
                line-height: 24px !important;
            }
        }
    }
}

#dragDrop {
    &.redBorderRequired {
        border: dashed 2px $warn-color !important;
    }
}

#qr_scanner {
    &__container {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;

        @include media-breakpoint-down(md) {
            padding: 1.5rem !important;
        }

        .scanner {
            aspect-ratio: 1 / 1;
            background:
                linear-gradient(to right, $gray-color 4px, transparent 4px) 0 0,
                linear-gradient(to right, $gray-color 4px, transparent 4px) 0 100%,
                linear-gradient(to left, $gray-color 4px, transparent 4px) 100% 0,
                linear-gradient(to left, $gray-color 4px, transparent 4px) 100% 100%,
                linear-gradient(to bottom, $gray-color 4px, transparent 4px) 0 0,
                linear-gradient(to bottom, $gray-color 4px, transparent 4px) 100% 0,
                linear-gradient(to top, $gray-color 4px, transparent 4px) 0 100%,
                linear-gradient(to top, $gray-color 4px, transparent 4px) 100% 100%;
            background-repeat: no-repeat;
            background-size: 30px 30px;
        }

        .error {
            font-size: 0.9rem;
            color: $error-color;
        }
    }
}

#coda-facility {
    &--container {
        background-color: $color-white;
        border-radius: 2px;

        .awb {
            span {
                font-size: 12px;
                line-height: 13px;
                color: #7d7c7c;
                letter-spacing: normal;
            }
        }

        .amount {
            span {
                font-size: 10px;
                line-height: 16px;
                color: #7d7c7c;
                letter-spacing: 1.5px;
            }
        }

        h6 {
            &.step_section {
                font-size: 20px !important;
                font-weight: 500 !important;
                letter-spacing: 0.15px !important;
                line-height: 24px !important;
            }
        }

        .mat-form-field-infix {
            display: flex !important;
        }

        .payment-instructions {
            border-radius: 9px;
            background-color: $grey-color-5;
            padding: 20px;

            &-download-csv {
                color: $primary-color;
                font-family: $family__body;
                font-size: 1em;
                background: transparent;
                cursor: pointer;
                margin-top: 14px;
            }
        }

        .drop-zone-area {
            border-radius: 5px;
            border: dashed 2px #c1c2c4;
            padding: 2rem 1rem;
            height: auto;
            margin-bottom: 5px;

            .hide {
                display: none !important;
            }

            p {
                color: $primary-color !important;
            }

            i {
                vertical-align: bottom;
                color: $primary-color !important;
                margin-right: 5px;
                font-size: 1.5rem;
            }

            .upload-button {
                position: relative;
                height: 50px;
                transition: 0.3s;
                border: 1px solid $primary-color;
                color: $primary-color !important;
                font-weight: $weight__bold;
                width: 25%;
                padding: 0 2rem !important;
                border-radius: 4px;
                line-height: 30px;
                text-align: center;

                @include media-breakpoint-down(lg) {
                    width: 50%;
                }

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                input {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }
        }

        .payment-uploaded-csv {
            border: 1px solid $grey-color-20;
            border-radius: 9px;
            padding: 14px 0;

            .file-icon {
                img {
                    height: 60px;
                    width: 45px;
                }
            }

            .file-info-area {
                width: 60%;

                .file-info-area-body_left {
                    flex: 1;

                    .file-name {
                        color: $grey-color-100;
                        font-family: $family__body;
                        font-size: 1em;
                        line-height: 17px;
                        margin-left: 16px;
                        padding-bottom: 6px;
                    }

                    .file-size {
                        color: $grey-color-60;
                        font-family: $family__body;
                        font-size: 0.75em;
                        line-height: 13px;
                        margin-left: 16px;
                        margin-bottom: -6px;
                    }
                }

                .progress {
                    height: 16px;
                    border: 1px solid $grey-color-5;
                    margin: 10px 16px;
                    width: 96%;

                    .progress-bar {
                        background-color: $primary-color;
                    }
                }
            }
        }

        .flux-container {
            margin: 1rem 1rem 2rem 1rem;
            padding: 0.5rem;
        }
        .input_width {
            width: 100%;
        }
    }

    #facility_data {
        border-radius: 3px;
        background-color: #edfaf7;

        label {
            font-size: 14px;
            font-weight: 500 !important;
            line-height: 16px;
            margin-bottom: 12px;
        }

        h6 {
            font-size: 20px !important;
            font-weight: 500 !important;
            letter-spacing: 0.15px !important;
            line-height: 24px !important;
        }

        p {
            color: $grey-color-100;
            font-size: 14px;
            letter-spacing: 0.4px;
            line-height: 16px;
        }

        span {
            &.facilities-data--type {
                color: $grey-color-100;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                align-items: center;
                letter-spacing: 0.4px;

                mat-icon {
                    height: 20px;
                    width: 20px;
                    border-radius: 3px;
                    background-color: #f2880f;
                    color: $color-white;
                    font-size: 18px;
                }
            }
        }
    }
    .facility-label {
        background-color: rgb(0, 110, 75);
        color: white;
        width: 100%;
        padding: 8px 10px;
        border-radius: 5px;
    }
    .search_btn {
        position: relative;
        top: 50%;
        transform: translateY(-25%);
    }
    .mbl_Serach_btn {
        color: rgb(19, 184, 159);
        font-weight: bold;
    }
    .mbl_Serach_btn:hover {
        cursor: pointer;
    }
    .custom-table {
        width: 100%;
        border-collapse: collapse;
    }

    .custom-table,
    .footer {
        text-align: end !important;
    }
    .custom-table th,
    .custom-table td {
        padding: 8px;
        text-align: left;
    }
    .custom-table tbody {
        background-color: #fff !important;
    }
    .custom-font {
        font-size: medium;
        font-weight: bold;
    }
}
#nested-tabel::ng-deep {
    #text-area {
        height: 19vh;
    }
    .d-flex {
        display: flex;
    }
    .align-items-center {
        align-items: center;
    }
    .fw-bold {
        margin-bottom: 0;
    }
}

.stickyBottom {
    width: calc(100% - 312px) !important;
    position: fixed;
    bottom: 0;
    z-index: 100;
    background-color: $color-white;
    border-radius: 2px;
    margin-left: -36px;
    padding: 1rem;
    box-shadow: 0 1px 4px 0 $containers-color-opacity;
    @media (max-width: 768px) {
        margin-left: -50px;
        .continue-btn-holder {
            display: block !important;
            padding: 5px 10px;
        }
    }
    @media (max-width: 600px) {
        width: 100vw !important;
        margin-left: -80px;
        padding: 5px 10px;
        .continue-btn-holder {
            display: block !important;
        }
    }
}
#common_facility_data {
    background-color: #dcf3ef;
    height: 142px;
    border-radius: 4px;
    gap: 8px;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    .title {
        color: #fff;
        height: 24px !important;
        border-radius: 4px;
        background-color: #007e59;
        font-family: Roboto;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.4%;
        display: flex;
        align-items: center;
        span {
            font-size: 16px;
            top: 2px;
            left: 1.33px;
            padding: 4px;
        }
    }
    .facility-name {
        height: 20px;
        padding-right: 1px;
        padding-left: 1px;
        gap: 10px;
        font-family: Lato;
        font-weight: 400;
        font-size: 17px;
        line-height: 100%;
        letter-spacing: 0.15px;
    }
    .facility-address {
        color: #5c5b5b;
        height: 16px;
        justify-content: space-between;
        padding: 1px;
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.5%;
    }

    span {
        &.facilities-data--type {
            color: #2196f3;
            font-family: Roboto;
            font-weight: 400;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.4%;
            height: 26px;
            padding: 1px;
            align-items: center;
            mat-icon {
                height: 17px;
                width: 17px;
                border-radius: 3px;
                background-color: #deb53e;
                color: $color-white;
                font-size: 17px;
            }
        }
    }
}
