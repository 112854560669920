/*********************************************************************************
*   SECTION: PAYMENTS DETAIL
*********************************************************************************/

.detail__sections {
    background: $background-color-light !important;

    button {
        color: $primary-color;
        font-weight: $weight__regular;
        letter-spacing: 1.25px;
        font-size: 1rem;
    }
}

p {
    color: $text-color-light;
    overflow-wrap: break-word;

    &.card-subtitle {
        color: $gray-color;
    }
}
