/*********************************************************************************
*   SECTION: COLORS
*********************************************************************************/

// GENERAL PALETTE

$secondary-color: #032747;
$secondary-color-lighter: #8193a3;
$secondary-color-darker: #021d37;

$warn-color: #ba1e27;
$warn-color-lighter: #dd8f93;
$warn-color-darker: #a31217;

$text-color-light: #032747;
$text-color-dark: #f4f5f7;

$background-color-light: #f4f5f7;
$background-color-dark: #032747;

$error-color: #e53935;
$error-color-lighter: #ff6f60;
$error-color-darker: #ab000d;
$error-color-700: #ab161d;

$success-color: #0c993b;
$success-color-lighter: #86cc9d;
$success-color-darker: #067c26;
$success-color-300: #55b876;
$success-color-800: #067c26;

$warning-color: #efda9f;
$warning-color-lighter: #e8cb78;
$warning-color-darker: #c88d1b;

$info-color: #2196f3;
$info-color-lighter: #90caf9;
$info-color-darker: #0d47a1;

$warm-color: #ba1e27;
$warm-color-lighter: #dd8f93;

$gray-color: #7d7c7c;
$gray-color-lighter: #7d7c7c;
$gray-color-darker: #0f0d0d;

$grey-color-5: #f7f7f7;
$grey-color-10: #f7273b;
$grey-color-20: #dedede;
$grey-color-60: #9d9c9c;
$grey-color-100: #5c5b5b;

$info-color-500: #2196f3;
$info-color-900: #0d47a1;

$color-white: #ffffff;
$color-black: #000000;
$border-button: #ededed;
$overlay: rgba(139, 122, 122, 0.45);
$overlay-sidenav: rgba(0, 0, 0, 0.05);
$primary-color-opacity-15: rgba(20, 187, 156, 0.08);
$containers-color-opacity: rgba(0, 0, 0, 0.19);
