/*********************************************************************************
*   SECTION: UPLOAD REQUEST
*********************************************************************************/

#upload-request {
    .drop-zone-area {
        border-radius: 2px;
        border: dashed 2px #c1c2c4;
        padding: 2rem 1rem;
        height: auto;

        .hide {
            display: none !important;
        }

        .mat-icon {
            height: 6rem;
            width: 6rem;
            font-size: 6rem;
            color: $primary-color;
            opacity: 0.5;
        }

        .upload-button {
            position: relative;
            height: 50px;
            transition: 0.3s;
            border: 1px solid $primary-color;
            font-weight: $weight__bold;
            color: $primary-color;
            width: 25%;
            padding: 0 2rem !important;
            border-radius: 4px;
            line-height: 30px;
            text-align: center;

            @include media-breakpoint-down(lg) {
                width: 50%;
            }

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            input {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }
    }

    &__header {
        h6 {
            font-weight: $weight__medium !important;
            margin: 0 1rem 0.2rem 0;
        }

        button {
            &.rejected_request__download {
                padding: 0 !important;
            }
        }

        .dates_header {
            .mat-icon {
                color: $primary-color;
            }
        }
    }

    &__container {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;
        padding: 2rem;

        .payment-instructions {
            border-radius: 9px;
            background-color: $grey-color-5;
            padding: 20px;

            &-download-csv {
                color: $primary-color;
                font-family: $family__body;
                font-size: 1em;
                background: transparent;
                cursor: pointer;
                margin-top: 14px;
            }
        }

        .files {
            .card {
                box-shadow: 0 1px 4px 0 $containers-color-opacity;

                button {
                    mat-icon {
                        color: $primary-color;
                        opacity: 0.6;
                    }
                }
            }
        }

        button {
            &[type='submit'] {
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
        }
    }
}
