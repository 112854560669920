#refund-request-choice {
    &__modal {
        background-color: $color-white;

        .refund-logo {
            padding: 24px 100px;

            @include media-breakpoint-down(md) {
                padding: 24px;
            }
        }

        .header {
            h4 {
                font-size: 1.875em;
            }
        }

        .sub-header {
            margin-bottom: 2em;

            .reference-number {
                background-color: $primary-color-50;
                border-radius: 4px;
                padding: 10px;
                flex-direction: column;

                .description {
                    max-width: 400px;
                    font-size: 1em !important;
                    font-weight: 400 !important;
                }
            }
        }
    }

    &__actions {
        button {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}
