#refund-status-update {
    @include media-breakpoint-up(md) {
        max-height: none;
        overflow: hidden;
    }
    &__modal {
        background-color: $color-white;

        .refund-logo {
            padding: 0px 100px 10px 100px;

            @include media-breakpoint-down(md) {
                padding: 24px;
            }
        }

        .header {
            h4 {
                font-size: 1.875em;
            }
        }

        .sub-header {
            margin-bottom: 1em;

            .reference-number {
                background-color: $primary-color-50;
                border-radius: 4px;
                padding: 10px;

                h6 {
                    font-size: 1.3125em !important;
                    color: $grey-color-100;
                    font-weight: 400 !important;
                }

                p {
                    font-family: $family__body;
                    font-size: 1em;
                    color: $primary-color;
                    font-weight: 700;
                }
            }
        }
    }

    &__actions {
        button {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }
}
