/*********************************************************************************
*   SECTION: TOOLTIP
*********************************************************************************/

.cs__tooltip {
    padding: 15px;
    margin-top: -20px;
    margin-bottom: 15px;
    width: 100% !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    display: block;
    border-radius: 3px;

    &--warning {
        background: $warning-color !important;
        color: $color-black !important;
    }

    &--info {
        background: $info-color !important;
        color: $color-white !important;
    }

    &--error {
        background: $error-color !important;
        color: $color-white !important;
    }
}

.mat-tooltip {
    background-color: rgba(0, 0, 0, 0.54);
    color: rgba(0, 0, 0, 0.54);
}
