/*********************************************************************************
*   SECTION: NGX BREADCRUMB
*********************************************************************************/

xng-breadcrumb {
    font-weight: $weight__medium;

    .xng-breadcrumb-item {
        a {
            &.xng-breadcrumb-link {
                color: $gray-color;

                &:hover {
                    color: $text-color-light;
                    text-decoration: underline !important;
                }
            }
        }

        label {
            &.xng-breadcrumb-trail {
                color: $primary-color;
            }
        }
    }
}
