/*********************************************************************************
*   SECTION: EMPTY CART
*********************************************************************************/

#payment-confirmation {
    background-color: $color-white;
    border-radius: 9px;
    .payment-confirmation--height {
        min-height: calc(100vh - 225px);
        height: calc(100vh - 225px);

        .card {
            background-color: $grey-color-5;
            border-radius: 8px;
            border: none;
            padding: 24px 22px;
            &--title {
                font-family: 'Mulish';
                color: #5c5b5b;
                font-size: 21px;
                font-weight: 700;
                line-height: 21px;
                letter-spacing: 0.032px;
                margin-bottom: 24px;
            }

            &--description {
                color: #5c5b5b;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0.08px;
                line-height: normal;
            }

            &--download {
                color: $primary_color;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 1.25px;
                line-height: 16px;

                button {
                    padding: 0 !important;
                    .mdc-button__label {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        h4 {
            letter-spacing: 0.2px;
        }

        p {
            color: $gray-color;
            letter-spacing: 0.2px;
            line-height: 23px;
        }

        button {
            white-space: initial;
            text-align: left;

            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .payment-confirmation-title {
            font-family: 'Mulish';
            font-size: 36px;
            line-height: 36px;
            letter-spacing: 0.09px;
            color: #000000;
        }

        .payment-confirmation-subtitle {
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.08px;
            color: #7d7c7c;
        }
    }
}
