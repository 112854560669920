.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-delete {
    color: $warn-color;
}

.foreground-disabled-text {
    color: $gray-color;
}