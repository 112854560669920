#bulkPaymentResult {
    th,
    td {
        padding: 0.5rem;
        border-bottom: 1px solid $grey-color-20;
    }

    td {
        vertical-align: baseline;
    }

    .tableHeader {
        padding: 0 24px 0 34px;
    }

    .mat-expansion-panel-header {
        padding-left: 10px;
    }

    .mat-expansion-panel-header[aria-disabled='true'] {
        color: unset;
        cursor: default;
    }

    .mat-expansion-panel-body {
        padding-bottom: 0;
        margin-left: 10px;
    }

    .mat-expansion-indicator {
        align-self: start;
    }

    .statusIcon {
        font-size: 18px;
        line-height: 24px;
        vertical-align: top;

        &.warning {
            color: $warn-color;
        }
    }

    .actions {
        margin-top: 28px;
        margin-bottom: 28px;

        .outlinedBtn {
            color: $primary-color;
            border: 2px solid $primary-color !important;
            background-color: transparent;
        }
    }
}
