/*********************************************************************************
*   SECTION: COLORS
*********************************************************************************/

// GENERAL PALETTE

$secondary-color: #032747;
$secondary-color-lighter: #364f73;
$secondary-color-darker: #000020;

$warn-color: #e53935;
$warn-color-lighter: #ff6f60;
$warn-color-darker: #ab000d;

$text-color-light: #032747;
$text-color-dark: #f4f5f7;

$background-color-light: #f4f5f7;
$background-color-dark: #032747;

$error-color: #e53935;
$error-color-lighter: #ff6f60;
$error-color-darker: #ab000d;
$error-color-700: #ab161d;

$success-color: #43a047;
$success-color-lighter: #76d275;
$success-color-darker: #00701a;
$success-color-300: #55b876;
$success-color-800: #067c26;

$warning-color: #fdd835;
$warning-color-lighter: #ffff6b;
$warning-color-darker: #c6a700;

$info-color: #1e88e5;
$info-color-lighter: #6ab7ff;
$info-color-darker: #005cb2;

$warm-color: #D1A245;
$warm-color-lighter: #F5E9C5;

$gray-color: #757575;
$gray-color-lighter: #a4a4a4;
$gray-color-darker: #494949;

$grey-color-5: #f7f7f7;
$grey-color-10: #f7273b;
$grey-color-20: #dedede;
$grey-color-60: #9d9c9c;
$grey-color-100: #5c5b5b;

$info-color-500: #2196f3;
$info-color-900: #0d47a1;

$color-white: #ffffff;
$color-black: #000000;
$border-button: #ededed;
$overlay: rgba(139, 122, 122, 0.45);
$overlay-sidenav: rgba(0, 0, 0, 0.05);
$primary-color-opacity-15: rgba(20, 187, 156, 0.08);
$containers-color-opacity: rgba(0, 0, 0, 0.19);
