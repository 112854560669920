@import './color-common';

/*********************************************************************************
*   SECTION: COLORS
*********************************************************************************/

// GENERAL PALETTE

$primary-color: #14bb9c;
$primary-color-lighter: #60eecd;
$primary-color-darker: #008a6e;
$primary-color-50: #dcf3ee;
$primary-color-900: #005130;

// ANGULAR MATERIAL PALETTE (DON'T CHANGE VARIABLE NAMES JUST VALUES)

$am-main-color: $primary-color;
$am-main-color-lighter: $primary-color-lighter;
$am-main-color-darker: $primary-color-darker;

$am-secondary-color: $secondary-color;
$am-secondary-color-lighter: $secondary-color-lighter;
$am-secondary-color-darker: $secondary-color-darker;

$am-warn-color: $warn-color;
$am-warn-color-lighter: $warn-color-lighter;
$am-warn-color-darker: $warn-color-darker;

$am-text-color-light: $text-color-light;
$am-text-color-dark: $text-color-dark;

$am-background-color-light: $background-color-light;
$am-background-color-dark: $background-color-dark;

// SWEET ALERT PALETTE (DON'T CHANGE VARIABLE NAMES JUST VALUES)

$sa-main-color: $primary-color;
$sa-main-color-lighter: $primary-color-lighter;
$sa-main-color-darker: $primary-color-darker;

$sa-secondary-color: $secondary-color;
$sa-secondary-color-lighter: $secondary-color-lighter;
$sa-secondary-color-darker: $secondary-color-darker;

$sa-warn-color: $warn-color;
$sa-warn-color-lighter: $warn-color-lighter;
$sa-warn-color-darker: $warn-color-darker;

$sa-text-color-light: $text-color-light;
$sa-text-color-dark: $text-color-dark;

$sa-background-color-light: $background-color-light;
$sa-background-color-dark: $background-color-dark;
