#debit-list {
    .mat-table tbody,
    .mat-paginator {
        background-color: white !important;
    }
}

div {
    &.COMPLETED {
        color: $success-color-800;
    }
    &.PENDING {
        color: $info-color-900;
    }
    &.FAILED {
        color: #a31217;
    }
}
