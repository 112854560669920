.basic-chip-list {
    .mat-chip-listbox-wrapper {
        gap: 10px;
    }

    .mat-form-field-underline {
        display: none !important;
    }

    .mat-mdc-chip.mat-mdc-standard-chip {
        background-color: transparent;
        border: 1px solid #009b75;

        .mat-mdc-chip-remove {
            color: black;
            opacity: 1;
        }
    }
}
