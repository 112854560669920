#form-header {
    figure {
        &.facility__logo {
            height: 50px;

            @include media-breakpoint-down(lg) {
                height: 35px;
            }
        }

        &.account__logo {
            height: 150px;

            @include media-breakpoint-down(lg) {
                height: 120px;
            }
        }
    }

    h5 {
        font-weight: 400 !important;
    }
}
