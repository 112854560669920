/*********************************************************************************
*   SECTION: AM TABLE
*********************************************************************************/
a {
    color: $primary-color !important;

    &:hover {
        color: $primary-color;
    }
}

.mat-sort-header-container {
    border: none !important;
}

table tr.overdue {
    border-left: 9px solid rgba($color: $warn-color, $alpha: 0.7);
}

table tr.almostDue {
    border-left: 9px solid rgba($color: $warning-color, $alpha: 0.7);
}

.mat-header-cell {
    font-weight: 500 !important;
    color: $text-color-light !important;
    font-size: 14px !important;
}

table td.mat-mdc-cell {
    white-space: pre-line;
    word-wrap: break-word;
    font-size: 15px !important;
    font-weight: 300 !important;

    .executeBtnFunction {
        white-space: pre-line;
        word-wrap: break-word;
        height: auto;
        line-height: $gr-lineHeight;
        min-height: 50px;
    }
}

.indicator {
    &__figure {
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
        margin-left: 1rem;

        &.overdue {
            background-color: rgba($color: $warn-color, $alpha: 0.7);
        }

        &.almostDue {
            background-color: rgba($color: $warning-color, $alpha: 0.7);
        }
    }
}

tr.detail-row {
    height: 0 !important;
}

tr.element-row:not(.expanded-row):hover {
    background: whitesmoke;
}

tr.element-row:not(.expanded-row):active {
    background: #efefef;
}

td.status-column {
    div {
        white-space: pre-line;
        word-wrap: break-word;
    }

    mat-icon {
        height: 20px;
        width: 24px;
        min-width: 24px;
        font-size: 20px;
    }
}

.REQUESTED,
.CARGOSPRINT_REQUESTS,
.IN_PROGRESS {
    color: $info-color-900 !important;
    font-size: 12px;
}

.APPROVED,
.FINALIZED,
.refunded {
    color: $success-color-800 !important;
    font-size: 12px;
}

.DENIED,
.REQUEST_CANCELED_BY_CUSTOMER {
    color: $error-color-700 !important;
    font-size: 12px;
}

.REQUESTED_CARGO_FACILITY {
    color: $info-color-900 !important;
    white-space: nowrap;
    font-size: 12px;
}

.element-row td {
    border-bottom-width: 1;
    border-bottom-color: rgba(3, 39, 71, 0.12);
}

.element-detail {
    overflow: hidden;
    display: flex;
}

.element-diagram {
    padding: 8px;
    font-weight: lighter;

    .mat-menu-item {
        padding: 16px;

        .mat-icon {
            margin-left: 16px;
        }
    }

    .mat-menu-item:hover {
        background-color: $primary-color-opacity-15;
        color: $primary-color;
        border-radius: 4px;
    }

    .mat-menu-item:hover .mat-icon {
        color: $primary-color;
    }
}

table {
    .mat-column-select,
    .mat-column-expand {
        width: 75px !important;
    }
    tr:last-child {
        border-bottom: 1px solid rgba(3, 39, 71, 0.12);
    }
}

.mat-column-select,
.mat-column-expand {
    width: 75px !important;
}

.mobile-label {
    display: none;
}

.refresh-btn-wrapper {
    margin-top: 1.34rem;

    button#refresh {
        min-height: 59px;
        padding: 0 15px !important;

        .mdc-button__label div {
            flex-wrap: wrap;
        }
    }
}

button.pay-with-bank-debit {
    min-width: 200px !important;
}

.download__toolbar {
    background-color: $primary-color-50 !important;
    border-radius: 4px;
    padding-right: 0 !important;
    z-index: 1 !important;
    min-height: 64px;

    span {
        font-size: 14px;
        margin-top: 3px;
    }
}

.mat-sort-header-content {
    text-align: left !important;
}

button.btn__actions--refund {
    white-space: normal !important;
    word-wrap: break-word !important;
    line-height: initial !important;
    padding: 0 15px !important;
}

.status-filter-toggle {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    padding: 0 1rem;
    font-weight: 500;
}

.status-update-btn {
    position: sticky !important;
    bottom: 0;
    width: 100%;
}

.cdk-overlay-pane {
    .status-filter {
        padding-bottom: 0;
    }

    &:has(.status-filter) {
        width: 100% !important;
        max-width: 340px;
    }
}

.paginator .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
}

/** POSSIBLE RESPONSIVE DESIGN FOR HEADERS (BASED ON THIS APPROACH: https://stackblitz.com/edit/angular-mohmt5-y88uhq?file=app%2Ftable-basic-example.ts)
@media(max-width: 600px) {

  table {
    &.mat-table {
      display: flex;
      flex-direction: column;

      tr.mat-row {
        display: flex;
        padding: 0;

        td {
          padding: 10px 0 !important;
          width: 100%;
          text-align: left !important;
        }
      }
    }
  }

  .mobile-label {
    width: 80px;
    font-weight: bold;
    white-space: pre-wrap;
    word-wrap: break-word;
    display: inline;
    max-width: 100%;
    min-height: 2em;
    margin-right: 5px;
  }

  .mat-header-row {
    display: none;
  }

  .mat-row {
    flex-direction: column;
    align-items: start;
    padding: 8px 24px;
  }
}
 */
