/*********************************************************************************
*   SECTION: SUPER ADMIN ACH PAYMENTS DETAIL
*********************************************************************************/

#ach_payment_detail {
    &__header {
        h6 {
            font-weight: $weight__medium !important;
            margin: 0 1rem 0.2rem 0;
        }
    }

    &__container {
        background-color: $color-white;
        border-radius: 2px;
        box-shadow: 0 1px 4px 0 $containers-color-opacity;

        .ach_payment_detail__ach-info {
            &--icon {
                img {
                    width: 133px;
                    height: 133px;
                }
            }

            &--wrapper {
                h6 {
                    font-weight: $weight__regular !important;
                    font-size: 1.3124rem;
                }

                .ach-content-wrapper {
                    padding: 14px;
                    border-radius: 4px;
                    background-color: $grey-color-5;
                }

                .ach-number,
                .ach-date,
                .ach-issued {
                    mat-icon {
                        color: $primary-color;
                        margin-right: 9px;
                    }

                    span {
                        font-size: $size__normal;
                    }
                }
            }
        }

        .ach_payment_detail__facility-payment {
            h6 {
                font-weight: $weight__regular !important;
            }
        }
    }
}
